import {
  Breadcrumbs,
  Button,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Box,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTable } from "inno-ui";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import updateIcon from "../../../../assets/img/pencilVector.svg";
import RefreshVector from "../../../../assets/img/refresh-cw.svg";
import { ButtonWithModalPerItem } from "../../../../components/atoms/Button";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import { Page, SkeletonComponent } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { getCookie } from "../../../../utils/constants";
import { emptyText } from "../../../../utils";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  formControl: {
    width: "180px",
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  search: {
    color: "#9AA2B1",
  },
  selectPadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
    },
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  dropdown: {
    color: "#000",
  },
  buttonAdd: {
    backgroundColor: "#000000",
    color: "white",
    height: 44,
    padding: "10px 28px 10px 28px",
  },
  textAdd: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "500",
    fontStyle: "normal",
    textTransform: "none",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
      fontFamily: ["Inter"].join(","),
    },
  },
  inputFields: {
    maxWidth: 268,
    "& .MuiOutlinedInput-input": {
      padding: "0px",
      height: "44px",
    },
  },
  wrapperFilter: {
    marginBottom: 20,
  },
  btnEdit: {
    backgroundColor: "#111827",
    color: "white",
    width: "28px",
    height: "28px",
    padding: "6px",
    borderRadius: "6px",
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
  btnDelete: {
    backgroundColor: "#111827",
    color: "white",
    width: "35px",
    height: "35px",
    padding: "6px",
    borderRadius: "6px",
    marginLeft: "10px",
    cursor: "pointer",
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
  tableChip: {
    marginRight: "5px",
    backgroundColor: "#F9FAFB",
    border: "1px solid #D1D5DC",
  },
}));

const totalSizeApi = 10;
const pageSize = 10;
function Lagu() {
  const classes = useStyles();
  const history = useHistory();
  const [searchKey, setSearchKey] = useState("");
  const [response, setResponse] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const [subPublisher, setOriginalPublisher] = useState([]);
  const [type, setType] = useState("");
  const [getColor, setGetColor] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  let role_name = userLogin?.role?.name;
  const handleChangeSelect = event => {
    setType(event.target.value);
    setPage(1);
  };

  const getResponse = async () => {
    try {
      setLoadingTable(true);
      const token = localStorage.getItem("token");
      const url =
        hardBaseUrl +
        `/publisher/song?page=${page}&size=${rowsPerPage}&search=${searchKey}&original_publisher_id=${type}`;
      const options = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const res = await axios.get(url, options);
      setResponse(res.data.data);
      setPage(res.data.meta.page);
      setTotalData(res.data.meta.total);
      setLoadingTable(false);
    } catch (error) {
      setLoadingTable(false);
    }
  };

  const getOriginalPublisher = async () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/song/originalpublisher`;

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios.get(url, options).then(res => {
      setOriginalPublisher(res.data.data);
    });
  };

  const getMainDsp = () => setGetColor("#000000");

  useEffect(() => {
    getOriginalPublisher();
    getMainDsp();
    getResponse();
  }, [page, searchKey, totalSizeApi, pageSize, type, rowsPerPage]);

  const handleChangeSearch = event => {
    setSearchKey(event.target.value);
  };

  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const handleChangePage = (_, data) => {
    setPage(data);
  };

  const handleDelete = id => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/song/${id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios.delete(url, config).then(res => {
      if (res.data.message === "success") {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Data deleted successfully",
        }).then(result => {
          if (result.isConfirmed === true) {
            getResponse();
          }
        });
      }
    });
  };

  const handleRecovery = id => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/song-recovery/${id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const formData = new FormData();
    axios.put(url, formData, config).then(res => {
      if (res.data.message === "success") {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Data recovery successfully",
        }).then(result => {
          if (result.isConfirmed === true) {
            getResponse();
          }
        });
      }
    });
  };

  const handleView = item => {
    const pathname = `/admin/parameter/lagu-admin/edit/${item?.song_id}`;
    const state = { customer: item };
    history.push({ pathname, state });
  };

  const customAction = item => {
    return (
      <div style={{ display: "flex" }}>
        <Tooltip title={"Update"} placement="bottom">
          <IconButton
            className={classes.btnEdit}
            style={{
              backgroundColor: getColor,
            }}
            onClick={() => handleView(item)}
          >
            <img
              src={updateIcon}
              alt="update-icon"
              style={{
                filter: " brightness(0) invert(1)",
              }}
            />
          </IconButton>
        </Tooltip>
        {item?.is_active_flag === true ? (
          <ButtonWithModalPerItem
            item={item}
            subTitle={"Are you sure you want to delete this data"}
            handleDelete={() => {
              handleDelete(item?.song_id);
            }}
          />
        ) : (
          <IconButton
            className={classes.btnEdit}
            style={{
              backgroundColor: getColor,
              marginLeft: "10px",
            }}
            onClick={() => {
              handleRecovery(item?.song_id);
            }}
          >
            <img
              src={RefreshVector}
              alt="update-icon"
              style={{
                filter: " brightness(0) invert(1)",
              }}
            />
          </IconButton>
        )}
      </div>
    );
  };

  const renderColumnChip = (list, type) => {
    const lessThanThree = list?.length > 3;
    return (
      <>
        {list !== null ? (
          <>
            {lessThanThree ? (
              <>
                {list?.slice(0, 3)?.map((item, index) => (
                  <Chip
                    key={`${type === "performer"
                      ? item?.performer_id
                      : item?.song_composer_id
                      }-${index}`}
                    label={
                      type === "performer"
                        ? item?.first_name
                        : item?.composer?.sure_name
                    }
                    className={classes.tableChip}
                    size="small"
                  />
                ))}
                <Chip
                  label={"+" + (list?.length - 3)}
                  className={classes.tableChip}
                  size="small"
                />
              </>
            ) : (
              list?.map((item, index) => (
                <Chip
                  key={`${type === "performer"
                    ? item?.performer_id
                    : item?.song_composer_id
                    }-${index}`}
                  label={
                    type === "performer"
                      ? item?.first_name
                      : item?.composer?.sure_name
                  }
                  className={classes.tableChip}
                  size="small"
                />
              ))
            )}
          </>
        ) : (
          "-"
        )}
      </>
    );
  };

  const columnTable = [
    {
      name: "all",
      title: "Action",
      renderText: item => customAction(item),
    },
    {
      name: "all",
      title: "ISRC Code",
      renderText: item => emptyText(item?.isrc_code),
    },
    {
      name: "all",
      title: "Song Title",
      renderText: item => emptyText(item?.title_song),
    },
    {
      name: "all",
      title: "Publisher",
      renderText: item => emptyText(item?.publisher_name),
    },
    {
      name: "all",
      title: "Original Publisher",
      renderText: item => emptyText(item?.original_publisher_name),
    },
    {
      name: "all",
      title: "Composer",
      renderText: item => renderColumnChip(item?.song_composer),
    },
    {
      name: "all",
      title: "Performer Name",
      renderText: item => renderColumnChip(item?.performers, "performer"),
    },
  ];

  return (
    <Page className={classes.root} title="Song">
      {loadingTable ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    lineHeight: "32px",
                  }}
                >
                  Parameter
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography className={classes.breadCrumbs}>Home</Typography>

                <Typography className={classes.breadCrumbs}>
                  Parameter
                </Typography>

                <Typography className={classes.breadCrumbsActive}>
                  Song
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          {role_name !== "Admin APMINDO" && <ButtonGroupTop />}
          <Divider className={classes.divider} />
          <Grid container className={classes.wrapperFilter} spacing={1}>
            <Grid item xs={12} md={6} sm={4} lg={7} xl={7}>
              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <OutlinedInput
                  placeholder="Search"
                  id="outlined-adornment-amount"
                  className={classes.inputFields}
                  value={searchKey}
                  onChange={event => handleChangeSearch(event)}
                  inputProps={{
                    classes: {
                      input: classes.placeholder,
                    },
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon className={classes.search} />
                    </InputAdornment>
                  }
                  fullWidth={false}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} sm={8} lg={5} xl={5}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Select
                      displayEmpty
                      className={classes.selectPadding}
                      value={type}
                      onChange={handleChangeSelect}
                      defaultValue={""}
                    >
                      <MenuItem value="">All Publisher</MenuItem>
                      {subPublisher.map((item, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={item.original_publisher_id}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button
                    href="/admin/parameter/lagu-admin/tambah"
                    className={classes.buttonAdd}
                    variant="contained"
                    size="large"
                    startIcon={<AddIcon />}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography component="span" className={classes.textAdd}>
                        Add Song
                      </Typography>
                    </ThemeProvider>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Box marginTop="20px">
            <InnoTable
              columns={columnTable}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              idColumnName={"performer_id"}
              isLoading={loadingTable}
              isUsingCheckbox={false}
              items={response || []}
              loadingColor={""}
              page={page}
              rowsPerPage={rowsPerPage}
              totalPage={pagesCount}
            />
          </Box>
        </Container>
      )}
    </Page>
  );
}

export default Lagu;
