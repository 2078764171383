import { Container, Divider } from "@material-ui/core";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  AutoCompleteComponent,
  DateRangePicker,
  DateTimeDisplay,
  ModalError,
  Page,
  SearchTextInput,
  SectionLabel,
  SkeletonComponent,
} from "components";
import { topMenuButtonMPIS } from "constants";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import { hardBaseUrl } from "../../../../services/urlConstant";

function Aktifitas(props) {
  const classes = globalStyles();
  const token = localStorage.getItem("token");
  const { userRole } = props;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingTable, setLoadingTable] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    search: "",
    action_id: 0,
    start_date: moment(),
    end_date: moment(),
  });
  const [optionAction, setOptionAction] = useState([]);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleResetPage = () => handleChangeQueryParams(1, "page");
  const handleChangeRowsPerPage = event => {
    const { value } = event?.target;
    handleChangeQueryParams(value, "size");
    handleResetPage();
  };
  const handleChangeSearch = event => {
    const { value } = event?.target;
    handleChangeQueryParams(value, "search");
    handleResetPage();
  };

  const getActionList = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/log/log-action`, { headers });
      const modifiedData = res?.data?.data?.map(item => ({
        label: item.action_name,
        id: item.log_action_id,
      }));
      setOptionAction(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingTable(false);
    }
  };
  const getDataTable = async () => {
    setLoadingTable(true);
    const params = {
      ...queryParams,
      start_date: formatDateFilter(queryParams?.start_date),
      end_date: formatDateFilter(queryParams?.end_date),
    };
    try {
      const res = await axios.get(`${hardBaseUrl}/log`, { headers, params });
      const { data, meta } = res?.data;
      setDataTable(data);
      const pageCount = getTotalPage(meta?.total, queryParams?.size);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingTable(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );

  useEffect(() => {
    getActionList();
  }, []);
  useEffect(() => {
    debounceDataTable();
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Activity">
      {loadingTable ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Configuration" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop items={topMenuButtonMPIS(userRole)} />
          <Divider className={classes.flatDivider} />
          <SectionLabel
            title="Activity"
            subTitle="Displays a list of user activity logs."
            mt="16px"
          />
          <Grid container justifyContent="space-between" my="16px">
            <Grid item>
              <SearchTextInput
                placeholder="Search"
                value={queryParams?.search}
                onChange={handleChangeSearch}
              />
            </Grid>
            <Grid item>
              <Grid container columnSpacing={2}>
                <Grid item>
                  <AutoCompleteComponent
                    options={optionAction}
                    label="Select Action"
                    value={
                      optionAction.find(
                        option => option.id === queryParams?.action_id
                      ) || null
                    }
                    onChange={value =>
                      handleChangeQueryParams(value, "action_id")
                    }
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <DateRangePicker
                    label="Date Range"
                    startDate={queryParams?.start_date}
                    handleChangeStartDate={date =>
                      handleChangeQueryParams(date, "start_date")
                    }
                    endDate={queryParams?.end_date}
                    handleChangeEndDate={date =>
                      handleChangeQueryParams(date, "end_date")
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <InnoTableV2
            columns={columnTable}
            handleChangePage={(_, data) =>
              handleChangeQueryParams(data, "page")
            }
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isLoading={loadingTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tableTotalPage}
          />
        </Container>
      )}
    </Page>
  );
}

const formatDateFilter = date => moment(date).format("YYYY-MM-DD");
const breadcrumbData = [
  {
    label: "Configuration",
    link: "/admin/konfigurasi/aktifitas-admin",
  },
  {
    label: "Activty",
    active: true,
  },
];
const columnTable = [
  {
    name: "all",
    title: "Date",
    renderText: item => (
      <DateTimeDisplay
        date={moment(
          `${item?.date} ${item?.time}`,
          "D-MMM-YYYY hh:mm:ss A"
        ).format()}
      />
    ),
  },
  {
    name: "created_by_name",
    title: "Account",
  },
  {
    name: "action",
    title: "Action",
  },
  {
    name: "content",
    title: "Description",
  },
];

export default Aktifitas;
