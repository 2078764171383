import { Container, Divider } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  FormLabel,
  ModalError,
  ModalSuccess,
  NumberInput,
  Page,
  PrimaryButton,
  SectionLabel,
  SkeletonComponent,
} from "components";
import { topMenuButtonMPIS } from "constants";
import { HeaderTitle } from "layouts";
import { useEffect, useState } from "react";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

function BillingKonfigurasi(props) {
  const classes = globalStyles();
  const { userRole } = props;
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [billingPercentage, setBillingPercentage] = useState(0);

  const getDetail = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/config/billing`, { headers });
      setBillingPercentage(res?.data?.data?.value_percentage);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleSubmit = async () => {
    try {
      await axios.post(
        `${hardBaseUrl}/config/billing`,
        { value_percentage: Number(billingPercentage) },
        { headers }
      );
      ModalSuccess("Successfully Change Billing Percentage").then(() =>
        getDetail()
      );
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <Page className={classes.root} title="Billing">
      {loadingPage ? (
        <SkeletonComponent variant="rect" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Configuration" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop items={topMenuButtonMPIS(userRole)} />
          <Divider className={classes.flatDivider} />
          <SectionLabel
            title="Billing"
            subTitle="This feature is used to set the percentage of billing."
            mt="16px"
          />
          <Box maxWidth="50%">
            <Box
              border="1px solid #ebebeb"
              borderRadius="5px"
              p="24px"
              mt="16px"
            >
              <FormLabel label="Billing Percentage" />
              <NumberInput
                value={billingPercentage}
                onChange={e => setBillingPercentage(e?.target?.value)}
                endAdornment="%"
              />
            </Box>
            <Grid container justifyContent="right" mt="16px">
              <PrimaryButton label="Save" onClick={handleSubmit} />
            </Grid>
          </Box>
        </Container>
      )}
    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Configuration",
    link: "/admin/konfigurasi/billing-configuration",
  },
  {
    label: "Billing",
    active: true,
  },
];

export default BillingKonfigurasi;
