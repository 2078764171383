import { Container, Divider } from "@material-ui/core";
import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import {
  AutoCompleteComponent,
  ButtonGroupTop,
  CheckboxInput,
  DateRangePicker,
  ModalError,
  MultipleSelectInput,
  Page,
  PrimaryButton,
  ReportModalWithTable,
  SearchTextInput,
  SkeletonComponent,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { globalStyles } from "styles";
import {
  allOptionsSelected,
  arrayToCommaSeparatedString,
  createAllFilterOption,
  formatDate,
  getCookie,
  getErrors,
  handleChangeFilterAll,
} from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { breadcrumbData, topMenuButton } from "../Components/SongClaimMenu";

function SongUnclaim() {
  const classes = globalStyles();
  const history = useHistory();
  const location = useLocation();
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const publisherId = userLogin?.publisher?.publisher_id;
  let token = localStorage.getItem("token");
  const headers = {
    Authorization: "Bearer " + token,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("per_page");
  const paramsHeaderId = urlParams.get("header_id");
  const paramsStartDate = urlParams.get("start_date");
  const paramsEndDate = urlParams.get("end_date");
  const paramsDSP = urlParams.get("dsp_id");

  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [optionDsp, setOptionDsp] = useState([]);
  const [optionProcessInfo, setOptionProcessInfo] = useState([]);
  const [processInfoParams, setProcessInfoParams] = useState({
    start_date: paramsStartDate
      ? formatGetPeriodParams(paramsStartDate)
      : moment().startOf("year"),
    end_date: paramsEndDate ? formatGetPeriodParams(paramsEndDate) : moment(),
  });
  const [selectedDsp, setSelectedDsp] = useState({
    dsp_id: 0,
    name: "None",
  });
  const [selectedProcessInfo, setSelectedProcessInfo] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: Number(paramsPage) || 1,
    per_page: Number(paramsSize) || 10,
    search: paramsSearch || "",
    header_id: paramsHeaderId || "",
  });
  const [dataTable, setDataTable] = useState([]);
  const [tablePageCount, setTablePageCount] = useState(0);
  const [selectedSongClaim, setSelectedSongClaim] = useState([]);
  const [modalReportVisible, setModalReportVisible] = useState(false);
  const [detailReport, setDetailReport] = useState({});

  const handleChangeProcessInfoParams = (value, key) => {
    setProcessInfoParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
    handleChangePageParams(formatSetPeriodParams(value), key);
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
    handleChangePageParams(value, key);
    if (key !== "page") {
      handleChangePageParams(1, "page");
    }
  };
  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeFilterDSP = value => {
    setSelectedDsp(optionDsp?.find(item => item?.dsp_id === Number(value)));
    handleChangePageParams(value, "dsp_id");
    if (value !== paramsDSP) {
      handleResetFilter("filter");
    }
    if (!value) {
      setOptionProcessInfo([]);
    }
  };
  const handleChangeFilterProcessInfo = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedProcessInfo(
      handleChangeFilterAll({
        selectedList: selectedProcessInfo,
        checked,
        option,
        key: "header_id",
        list: optionProcessInfo,
        handleChangeQueryParams,
      })
    );
  };
  const handleSelectSong = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedSongClaim(prev => {
      const selectedSong = checked
        ? [...prev, option]
        : prev.filter(song => song.id !== option.id);
      return selectedSong;
    });
  };
  const handleShowReportDetail = async data => {
    setDetailReport(data);
    setModalReportVisible(true);
  };
  const handleCloseModalReport = () => {
    setModalReportVisible(false);
    getDataTable();
    handleResetFilter();
  };
  const handleResetFilter = type => {
    if (type === "filter") {
      setSelectedProcessInfo([]);
      handleChangeQueryParams("", "header_id");
    }
    setSelectedSongClaim([]);
  };

  const getOptionDSP = async () => {
    try {
      setLoadingFilter(true);
      const url = `${hardBaseUrl}/publishers/list/${publisherId}/dsps`;
      const res = await axios.get(url, { headers });
      const modifiedData = res?.data?.data?.map(item => ({
        ...item,
        id: item?.dsp_id,
        label: item?.name,
      }));
      setOptionDsp(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingFilter(false);
    }
  };
  const getProcessInfoList = async () => {
    try {
      setLoadingFilter(true);
      const res = await axios.get(
        `${hardBaseUrl}/unclaim/header/${selectedDsp?.dsp_id}`,
        {
          headers,
          params: {
            start_date: formatSetPeriodParams(processInfoParams?.start_date),
            end_date: formatSetPeriodParams(processInfoParams?.end_date),
          },
        }
      );
      const { data } = res?.data;
      const updatedList = [
        createAllFilterOption({ key: "header_id", label: "name" }),
        ...data,
      ];
      setOptionProcessInfo(updatedList);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingFilter(false);
    }
  };
  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/unclaim/datatable`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.found, queryParams?.per_page);
      setTablePageCount(pageCount || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const postClaimSong = async () => {
    setLoadingButton(true);
    const modifiedSelectedSong = selectedSongClaim?.map(item => ({
      ...item,
      publisher_id: publisherId,
    }));
    let payload = {
      dsp_id: Number(selectedDsp?.dsp_id),
      t_unclaim_detail: modifiedSelectedSong,
    };
    try {
      const res = await axios.post(`${hardBaseUrl}/unclaim/claim`, payload, {
        headers,
      });
      handleShowReportDetail(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton(false);
    }
  };

  useEffect(() => {
    getOptionDSP();
  }, []);
  useEffect(() => {
    if (selectedDsp?.dsp_id) {
      getProcessInfoList();
    }
  }, [processInfoParams, selectedDsp]);
  useEffect(() => {
    const hasSelectedProcessInfo = selectedProcessInfo?.length > 0;
    const isSearching = Boolean(queryParams?.search);

    if (!hasSelectedProcessInfo) return;
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [selectedProcessInfo, queryParams, debounceDataTable]);
  useEffect(() => {
    if (optionDsp && optionDsp.length > 0 && paramsDSP) {
      handleChangeFilterDSP(paramsDSP);
    }
  }, [optionDsp]);
  useEffect(() => {
    if (optionProcessInfo && optionProcessInfo.length > 0 && paramsHeaderId) {
      const selectedId = paramsHeaderId?.split(",")?.map(id => id.trim());
      const selected = optionProcessInfo.filter(option =>
        selectedId.includes(option.header_id.toString())
      );
      setSelectedProcessInfo(
        allOptionsSelected({
          list: optionProcessInfo,
          key: "header_id",
          selectedFilter: selected,
        })
          ? optionProcessInfo
          : selected
      );
    }
  }, [optionProcessInfo]);

  return (
    <Page className={classes?.root} title="Unclaim">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Unclaim" breadcrumbData={breadcrumbData} />
          <Divider className={classes?.divider} />
          <ButtonGroupTop items={topMenuButton(true)} />
          <Grid
            container
            justifyContent={
              selectedProcessInfo.length > 0 ? "space-between" : "right"
            }
            spacing={1}
            direction="row-reverse"
          >
            <Grid item>
              <Grid container columnSpacing={1} direction="row">
                <Grid item>
                  <DateRangePicker
                    label="Date Range"
                    startDate={processInfoParams?.start_date}
                    handleChangeStartDate={date =>
                      handleChangeProcessInfoParams(date, "start_date")
                    }
                    endDate={processInfoParams?.end_date}
                    handleChangeEndDate={date =>
                      handleChangeProcessInfoParams(date, "end_date")
                    }
                  />
                </Grid>
                <Grid item>
                  <AutoCompleteComponent
                    label="DSP"
                    options={optionDsp}
                    value={
                      optionDsp?.find(
                        option => option?.id === Number(selectedDsp?.dsp_id)
                      ) || null
                    }
                    onChange={handleChangeFilterDSP}
                    width={300}
                    size="small"
                    disabled={loadingFilter}
                  />
                </Grid>
                {optionProcessInfo.length > 1 && (
                  <Grid item>
                    <MultipleSelectInput
                      label="Process Info"
                      placeholder="Process Info"
                      value={selectedProcessInfo}
                      textValue={
                        selectedProcessInfo.length > 0 &&
                          selectedProcessInfo.some(
                            item => item?.header_id === "all"
                          )
                          ? "All"
                          : arrayToCommaSeparatedString(
                            selectedProcessInfo,
                            "header_id"
                          )
                      }
                      options={optionProcessInfo}
                      optionKey={"header_id"}
                      onChange={handleChangeFilterProcessInfo}
                      checkBoxLabel={item =>
                        optionProcessInfo?.length
                          ? item?.header_id === "all"
                            ? "All"
                            : `${item?.header_id} : (${formatDate(
                              item?.start_date
                            )} - ${formatDate(item?.end_date)})`
                          : null
                      }
                      width={300}
                      disabled={loadingFilter}
                      loading={loadingFilter}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {selectedProcessInfo?.length > 0 && (
              <Grid item>
                <SearchTextInput
                  label="Search"
                  placeholder="Song Title, Composer, Artist, ISWC, ISRC"
                  value={queryParams?.search}
                  onChange={event =>
                    handleChangeQueryParams(event?.target?.value, "search")
                  }
                  width={300}
                />
              </Grid>
            )}
          </Grid>
          {selectedProcessInfo.length > 0 ? (
            <Box mt="24px">
              <InnoTableV2
                isLoading={false}
                columns={columnTable({
                  selectedSongClaim,
                  handleSelectSong,
                  selectedDsp,
                })}
                items={dataTable}
                page={queryParams?.page}
                rowsPerPage={queryParams?.per_page}
                totalPage={tablePageCount}
                handleChangePage={(_, data) =>
                  handleChangeQueryParams(data, "page")
                }
                handleChangeRowsPerPage={e =>
                  handleChangeQueryParams(e?.target?.value, "per_page")
                }
              />
              {selectedSongClaim?.length > 0 && (
                <Grid container justifyContent="right" m="16px 0">
                  <PrimaryButton
                    label={`${loadingButton ? "Processing Claim ..." : "Claim This Song"
                      }`}
                    onClick={postClaimSong}
                    disabled={loadingButton}
                    loading={loadingButton}
                  />
                </Grid>
              )}
            </Box>
          ) : (
            <Typography
              fontSize={18}
              fontWeight={400}
              color="#687083"
              textAlign="center"
              my="40px"
            >
              Select Date Range and DSP to view song unclaim&apos;s data
            </Typography>
          )}
          <ReportModalWithTable
            detailReport={detailReport}
            modalVisible={modalReportVisible}
            onClose={handleCloseModalReport}
          />
        </Container>
      )}
    </Page>
  );
}

const formatGetPeriodParams = date => moment(date, "YYYY-MM-DD");
const formatSetPeriodParams = date => moment(date).format("YYYY-MM-DD");
const columnTable = ({ selectedSongClaim, handleSelectSong, selectedDsp }) => [
  {
    name: "all",
    title: "",
    renderText: item => (
      <CheckboxInput
        checked={selectedSongClaim?.some(selected => selected?.id === item?.id)}
        onChange={e => handleSelectSong(e, item)}
      />
    ),
  },
  {
    name: "song_title",
    title: "Song Title",
  },
  {
    name: "isrc_code",
    title: "ISRC Code",
  },
  {
    name: "iswc",
    title: "ISWC Code",
  },
  {
    name: "composer_names",
    title: "Composer/Author",
  },
  {
    name: "artist",
    title: "Artist",
  },
  {
    name: "number_of_streams",
    title: "Number of Stream",
  },
  {
    name: "all",
    title: "DSP Name",
    renderText: () => selectedDsp?.name,
  },
  {
    name: "process_date",
    title: "Process Date",
    renderText: date => formatDate(date),
  },
  {
    name: "all",
    title: "Period Date",
    renderText: item =>
      `${formatDate(item?.start_date)} - ${formatDate(item?.end_date)}`,
  },
];
export default SongUnclaim;
