import { Container, Divider } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  FormLabel,
  ModalError,
  ModalSuccess,
  Page,
  PasswordInput,
  PrimaryButton,
  SectionLabel,
  SkeletonComponent,
  TextInput,
} from "components";
import { topMenuButtonMPIS } from "constants";
import { HeaderTitle } from "layouts";
import { useEffect, useState } from "react";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

function Sistem(props) {
  const classes = globalStyles();
  const { userRole } = props;
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [payload, setPayload] = useState({
    ip_email_server: "",
    password: "",
    username: "",
  });

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };

  const getDetail = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/system`, { headers });
      setPayload(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  const handleSubmitPayload = async () => {
    try {
      setLoadingPage(true);
      await axios.put(`${hardBaseUrl}/system`, payload, {
        headers,
      });
      ModalSuccess("System Configuration Data Saved Successfully").then(
        res => res?.isConfirmed && getDetail()
      );
    } catch (error) {
      ModalError(getErrors(error?.response) || "Error Saving Configuration");
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <Page className={classes.root} title="System">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <>
          <Container maxWidth={false}>
            <HeaderTitle
              title="Configuration"
              breadcrumbData={breadcrumbData}
            />
            <ButtonGroupTop items={topMenuButtonMPIS(userRole)} />
            <Divider className={classes.flatDivider} />
            <SectionLabel
              title="Server"
              subTitle="This feature is used to set the general configuration of the system."
              mt="16px"
            />
            <Grid container>
              <Grid item xs={8}>
                <Box
                  border="1px solid #E4E7EB"
                  borderRadius="8px"
                  padding="24px"
                  my="16px"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormLabel label="Sender Email Address" />
                      <TextInput
                        value={payload?.ip_email_server}
                        onChange={e =>
                          handleChangePayload(
                            e?.target?.value,
                            "ip_email_server"
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormLabel label="Username" />
                      <TextInput
                        value={payload?.username}
                        onChange={e =>
                          handleChangePayload(e?.target?.value, "username")
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormLabel label="Password" />
                      <PasswordInput
                        value={payload?.password}
                        onChange={e =>
                          handleChangePayload(e?.target?.value, "password")
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Grid container justifyContent="right">
                  <PrimaryButton label="Save" onClick={handleSubmitPayload} />
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Configuration",
    link: "/admin/konfigurasi/sistem",
  },
  {
    label: "System",
    active: true,
  },
];
export default Sistem;
