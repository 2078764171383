import {
  Button,
  Container,
  FormControl,
  InputAdornment,
  Box,
  Paper,
  TextField,
  Typography,
  Divider,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import { styled } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTable } from "inno-ui";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import {
  ButtonGroupTop,
  PrimaryButton,
} from "../../../../components/atoms/Button";
import HeaderPage from "../../../../layouts/Header/HeaderPage";
import { Page, SkeletonComponent } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const CustomDivider = styled(Divider)(() => ({
  marginBottom: 16,
  borderTop: "1px solid #e1e1e1",
}));
const listMenuAssosiation = [
  {
    id: 0,
    title: "Song",
    link: "/admin/parameter/lagu",
    selected: true,
  },
  {
    id: 1,
    title: "Composer/Author",
    link: "/admin/parameter/pencipta",
    selected: false,
  },
  {
    id: 2,
    title: "Performer",
    link: "/admin/parameter/performer",
    selected: false,
  },
];

const menuForSociety = [
  {
    id: 0,
    title: "Song",
    link: "/admin/parameter/lagu",
    selected: false,
  },
  {
    id: 1,
    title: "Composer/Author",
    link: "/admin/parameter/pencipta",
    selected: false,
  },
  {
    id: 2,
    title: "Performer",
    link: "/admin/parameter/performer",
    selected: false,
  },
  {
    id: 3,
    title: "Publisher",
    link: "/admin/parameter/publisher",
    selected: false,
  },
];

const menuForAssosiate = {
  id: 3,
  title: "Publisher",
  link: "/admin/parameter/publisher",
  selected: false,
};
function Publisher({ userRole, userLogin }) {
  const history = useHistory();
  const [response, setResponse] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [imageToOpen, setImageToOpen] = useState(null);
  const pagesCount = Math.ceil(totalData / rowsPerPage);
  const getResponse = () => {
    setLoadingTable(true);
    let theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher?page=${page}&size=${rowsPerPage}&search=${searchKey}`;
    const headers = {
      headers: {
        Authorization: `Bearer ${theToken}`,
      },
    };
    axios
      .get(url, headers)
      .then(res => {
        setResponse(res.data.data || []);
        setTotalData(res.data.meta.total);
        setPage(res.data.meta.page);
        setLoadingTable(false);
      })
      .catch(() => {
        setLoadingTable(false);
      });
  };
  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);
    setPage(1);
  };
  const handleChangePage = (event, data) => {
    setPage(data);
  };
  const handleChangeSearch = event => {
    setPage(1);
    setSearchKey(event.target.value);
  };
  const handleDelete = async ({ publisher_id }) => {
    const url = `${hardBaseUrl}/publisher/${publisher_id}`;
    let theToken = localStorage.getItem("token");
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${theToken}`,
      },
    };
    axios.delete(url, headers).then(res => {
      if (res.data.message === "success") {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Data has been deleted",
        }).then(result => {
          if (result.isConfirmed === true) {
            getResponse();
          }
        });
      }
    });
  };

  const handleRecovery = ({ publisher_id }) => {
    const url = `${hardBaseUrl}/publisher-recovery/${publisher_id}`;
    let theToken = localStorage.getItem("token");
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${theToken}`,
      },
    };
    const formData = new FormData();
    axios.put(url, formData, headers).then(res => {
      if (res.data.message === "success") {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Data has been recovered",
        }).then(result => {
          if (result.isConfirmed === true) {
            getResponse();
          }
        });
      }
    });
  };
  useEffect(() => {
    getResponse();
  }, [rowsPerPage, page, searchKey]);
  const isAssociate = userRole === "association";
  const conditionMenu = {
    admin: [],
    association: [...listMenuAssosiation, menuForAssosiate],
    society: menuForSociety,
  };
  const usedMenu =
    conditionMenu[userRole || userLogin?.role?.type] || listMenuAssosiation;
  const handleClickImage = item => {
    setImageToOpen(item.profile_image);
    setOpenImage(true);
  };
  const handleCloseImage = () => {
    setOpenImage(false);
  };
  const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(3),
    },
    content: {},
    buttonAdd: {
      height: "44px",
      width: "auto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      backgroundColor: "black",
      color: "white",
      textTransform: "none",
    },
    textAdd: {
      color: "white",
      fontSize: "14px",
    },
    contentAdd: {
      marginTop: "20px",
      marginLeft: "20px",
      marginRight: "20px",
      fontFamily: ["Inter"].join(","),
    },
    pageSize: {
      height: "30px",
      borderRadius: "6px",
      border: "1px solid #D1D5DC",
      paddingLeft: "5px",
      paddingRight: "5px",
      marginTop: "5px",
    },
    paginationText: {
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
    },
    inputFields: {
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
      "& .MuiOutlinedInput-root": {
        width: "312px",
        height: "44px",
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },
    width40: {
      width: "40px",
    },
    cancelIcon: {
      color: "black",
      position: "absolute",
      right: 0,
    },
  }));
  const classes = useStyles();
  const renderLogo = (item, key = "") => {
    return (
      <React.Fragment>
        <img
          onClick={() => {
            handleClickImage(item);
          }}
          src={item[key]}
          alt=""
          className={classes.width40}
        />
      </React.Fragment>
    );
  };
  return (
    <Fragment>
      <Page className={!isAssociate && classes.root} title="Publisher">
        <Container maxWidth={false}>
          <HeaderPage
            title="Parameter"
            breadcrumbs={["Parameter", "Publisher"]}
          />
          <ButtonGroupTop items={usedMenu} />
          <div className={classes.content}>
            <CustomDivider />
            <Box display="flex" justifyContent="space-between">
              <FormControl className={classes.margin} variant="outlined">
                <TextField
                  id="input-with-icon-textfield"
                  variant="outlined"
                  className={classes.inputFields}
                  onChange={handleChangeSearch}
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          alt="Logo"
                          src={
                            require("assets/image-public/images/search.svg")
                              .default
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>

              <PrimaryButton
                onClick={() => history.push("/admin/parameter/publisher/add")}
                label="Add Publisher"
              />
            </Box>
            {loadingTable ? (
              <SkeletonComponent variant="wave" />
            ) : (
              <Box marginTop="30px">
                <InnoTable
                  columns={[
                    {
                      name: "all",
                      title: "Logo",
                      renderText: item => renderLogo(item, "profile_image"),
                    },
                    {
                      name: "name",
                      title: "Publisher",
                    },
                    {
                      name: "publisher_id",
                      title: "ID Publisher",
                    },
                    {
                      name: "subdomain",
                      title: "URL",
                    },
                    {
                      name: "email",
                      title: "Email",
                    },
                    {
                      name: "phone",
                      title: "Phone",
                    },
                  ]}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleEdit={item => {
                    history.push({
                      pathname: `/admin/parameter/publisher/${item.publisher_id}`,
                      state: { customer: item },
                    });
                  }}
                  handleDelete={handleDelete}
                  idColumnName={"publisher_id"}
                  isLoading={loadingTable}
                  isHaveAction
                  deleteName={"name"}
                  isUsingCheckbox={false}
                  isUsingDeleteConfirmation
                  items={response}
                  handleRefresh={{
                    basedOnField: "is_active_flag",
                    function: handleRecovery,
                  }}
                  loadingColor={""}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  totalPage={pagesCount}
                />
              </Box>
            )}

            <Dialog
              onClose={handleCloseImage}
              aria-labelledby="simple-dialog-title"
              open={openImage}
            >
              <Paper elevation={0} />
              <CancelIcon
                onClick={() => {
                  setOpenImage(false);
                }}
                className={classes.cancelIcon}
              />
              <img src={imageToOpen} alt="" />
              <Paper />
            </Dialog>
          </div>
        </Container>
      </Page>
    </Fragment>
  );
}
export default Publisher;
