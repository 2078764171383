/* eslint-disable no-unused-vars */
/* eslint-disable react/no-multi-comp */
import {
  CircularProgress,
  colors,
  Container,
  Drawer,
  Hidden,
  List,
  // ListSubheader,
  Typography
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { InnoImage } from "inno-ui";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { matchPath, useLocation } from "react-router";
import CARIS from "../../assets/img/newCarisLogo.webp";
import MPIS from "../../assets/img/newMpisLogo.webp";
import { hardTypeWeb } from "../../services/urlConstant";
import NavItem from "../Header/Navbar/NavItem";

const theme = createTheme({
  typography: {
    fontFamily: [
      // "-apple-system",
      // "BlinkMacSystemFont",
      // '"Segoe UI"',
      // "Roboto",
      // '"Helvetica Neue"',
      // "Arial",
      // "sans-serif",
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
      "Inter",
    ].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F9FAFB",
  },
  rootCircular: {
    margin: "auto",
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    zIndex: 999,
    width: 256,
    // top: 64,
    top: 0,
    // height: 'calc(100% - 64px)'
    height: "calc(100%)",
  },
  navigation: {
    // backgroundColor: 'yellow',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    overflow: "auto",
    // padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
  profile: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  badgeDot: {
    height: 9,
    minWidth: 9,
  },
  onlineBadge: {
    backgroundColor: colors.green[600],
  },
  awayBadge: {
    backgroundColor: colors.orange[600],
  },
  busyBadge: {
    backgroundColor: colors.red[600],
  },
  offlineBadge: {
    backgroundColor: colors.grey[300],
  },
  avatar: {
    cursor: "pointer",
    width: 40,
    height: 40,
  },
  details: {
    marginLeft: theme.spacing(2),
  },
  moreButton: {
    marginLeft: "auto",
    color: colors.blueGrey[200],
  },
}));

function renderNavItems({
  // eslint-disable-next-line react/prop-types
  items,
  // subheader,
  key,
  ...rest
}) {
  if (typeof key === "undefined") {
    key = "x";
  }
  // return (
  //   <>
  //     {items.reduce(
  //       // eslint-disable-next-line no-use-before-define
  //       (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
  //       []
  //     )}
  //   </>
  // )
  return (
    <List
      // key={`1`}
      key={key}
    >
      {/* eslint-disable-next-line react/prop-types */}
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ theColor, acc, pathname, item, depth = 0 }) {
  // if (item.show) {
  if (typeof item.items !== "undefined") {
    // Have a child
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        coloractive={theColor}
        depth={depth}
        icon={item.icon}
        selectedicon={item.selectedicon}
        key={item.href}
        label={item.label}
        open={Boolean(open)}
        // open={true}
        title={item.title}
      // show={item.show}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          key: item.title,
        })}
      </NavItem>
    );
  } else {

    acc.push(
      <NavItem
        coloractive={theColor}
        depth={depth}
        href={item.href}
        icon={item.icon}
        selectedicon={item.selectedicon}
        key={item.href}
        label={item.label}
        title={item.title}
      // before={before}
      />
    );
  }

  return acc;
}

function NavBar({
  loadingComponent,
  selectedNavItems,
  openMobile,
  onMobileClose,
  className,
  theColor,
  ...rest
}) {
  const classes = useStyles();
  const location = useLocation();

  const MpisBottomBar = () => {
    if (hardTypeWeb === "mpis") {
      return (
        <div className={classes.profile}>
          <Container
            style={{
              // backgroundColor: 'green',
              minHeight: "64px",
              display: "flex",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#687083",
                  marginTop: "45px",
                }}
              >
                Powered by
              </Typography>
            </ThemeProvider>
            <InnoImage
              alt="Logo"
              src={MPIS}
              styleImageContainer={{
                boxShadow: "none",
                padding: 0,
                backgroundColor: "transparent",
              }}
              styleImage={{
                width: "80px",
                display: "block",
                marginLeft: "25px",
                height: "80px",
              }}
              fill={true}
            />
          </Container>
        </div>
      );
    } else if (
      hardTypeWeb === "nadaku" ||
      hardTypeWeb === "musica" ||
      hardTypeWeb === "aquarius" ||
      hardTypeWeb === "mymusic" ||
      hardTypeWeb === "argaswarakencanamusik" ||
      hardTypeWeb === "jagaddhita" ||
      hardTypeWeb === "epublishing" ||
      hardTypeWeb === "karyaanakhoki" ||
      hardTypeWeb === "ciptasimphoniindah" ||
      hardTypeWeb === "prodigi" ||
      hardTypeWeb === "alfapustaka" ||
      hardTypeWeb === "gmpublishing" ||
      hardTypeWeb === "sci" ||
      hardTypeWeb === "mahakaryabagus" ||
      hardTypeWeb === "jk-records" ||
      hardTypeWeb === "indosemar" ||
      hardTypeWeb === "27musik" ||
      hardTypeWeb === "limaduabelas" ||
      hardTypeWeb === "rpm" ||
      hardTypeWeb === "gpmi" ||
      hardTypeWeb === "damarlangit" ||
      hardTypeWeb === "tbw" ||
      hardTypeWeb === "harmonidwiselaras" ||
      hardTypeWeb === "harmoni" ||
      hardTypeWeb === "jawara" ||
      hardTypeWeb === "starcipta" ||
      hardTypeWeb === "logiskreatifpublisherindo" ||
      hardTypeWeb === "maharpustakanusantara"
    ) {
      return (
        <div className={classes.profile}>
          <Container
            style={{
              // backgroundColor: 'green',
              minHeight: "64px",
              display: "flex",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#687083",
                  marginTop: "25px",
                }}
              >
                Powered by
              </Typography>
            </ThemeProvider>
            <InnoImage
              alt="Logo"
              src={CARIS}
              styleImageContainer={{
                boxShadow: "none",
                padding: 0,
                backgroundColor: "transparent",
              }}
              styleImage={{
                width: "99.73px",
                display: "block",
                marginLeft: "5px",
                height: "30px",
                marginTop: "20px",
              }}
              fill={true}
            />
          </Container>
        </div>
      );
    } else {
      return (
        <div className={classes.profile}>
          <Container
            style={{
              // backgroundColor: 'green',
              minHeight: "64px",
              display: "flex",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#687083",
                  marginTop: "25px",
                }}
              >
                Powered by
              </Typography>
            </ThemeProvider>
            <InnoImage
              alt="Logo"
              src={CARIS}
              styleImageContainer={{
                boxShadow: "none",
                padding: 0,
                backgroundColor: "transparent",
              }}
              styleImage={{
                width: "99.73px",
                display: "block",
                marginLeft: "5px",
                height: "30px",
                marginTop: "20px",
                // marginRight: '10px'
                // color: 'red'
              }}
              fill={true}
            // style={{

            //   // marginRight: '10px'
            //   // color: 'red'
            // }}
            />
          </Container>
        </div>
      );
    }
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <div
        style={{
          backgroundColor: { theColor },
          // backgroundColor: '#F9FAFB',
          // minHeight: '64px',
          // minHeight: '48px',
          height: "64px",
          // height: 'auto',
          // display: 'none',
          display: "flex",
          margin: "auto",
        }}
        onClick={() => console.log("bubu")}
      ></div>

      <nav className={classes.navigation}>
        <>
          {selectedNavItems.map(list =>
            renderNavItems({
              items: list.items,
              subheader: list.subheader,
              pathname: location.pathname,
              key: list.title,
            })
          )}
        </>
      </nav>
      <MpisBottomBar />
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer,
          }}
          open
          variant="persistent"
        >
          {loadingComponent ? (
            <>
              <div className={classes.rootCircular}>
                <CircularProgress disableShrink />
              </div>
            </>
          ) : (
            <>{content}</>
          )}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  theName: PropTypes.string,
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  loadingComponent: PropTypes.bool,
  selectedNavItems: PropTypes.array,
  theSideMenus: PropTypes.array,
};

export default NavBar;
