import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Container } from "@material-ui/core";
import axios from "axios";
import { useLocation } from "react-router-dom";
import {
  ButtonGroupTop,
  PrimaryButton,
  Page,
  SearchTextInput,
  SkeletonComponent,
  ModalError,
} from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { topMenuParameter } from "constants";
import { getErrors } from "utils";
import { debounce } from "lodash";
import { HeaderTitle } from "layouts";
import { Add } from "@material-ui/icons";
import { InnoTableV2 } from "inno-ui";
import { Box, Divider, Grid, styled } from "@mui/material";

function Dsp() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: Number(paramsPage) || 1,
    per_page: Number(paramsSize) || 10,
    search: paramsSearch || "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handleChangeSearch = event => {
    const value = event?.target?.value;
    handleChangeQueryParams(value, "search");
    handleChangePageParams(value, "search");
  };
  const handleChangePage = page => {
    handleChangeQueryParams(page, "page");
    handleChangePageParams(page, "page");
  };
  const handleChangePageSize = size => {
    handleChangeQueryParams(size, "per_page");
    handleChangePageParams(size, "size");
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/master/client`, {
        headers,
        params: queryParams,
      });

      const { data } = res?.data;
      setDataTable(data?.data);
      setTableTotalPage(data?.total_page);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );

  useEffect(() => {
    debounceDataTable();
    if (queryParams?.page !== paramsPage) {
      handleChangePageParams(queryParams?.page, "page");
    }
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Client">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Client" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop items={topMenuParameter} />
          <CustomDivider />
          <Grid container justifyContent="space-between" mb="16px">
            <Grid item>
              <SearchTextInput
                placeholder="Search"
                value={queryParams?.search}
                onChange={handleChangeSearch}
              />
            </Grid>
            <Grid item>
              <PrimaryButton
                label="Add Client Name"
                startIcon={<Add />}
                onClick={() => history.push("/admin/parameter/client/add")}
              />
            </Grid>
          </Grid>
          <InnoTableV2
            isLoading={loadingPage}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.per_page}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) => handleChangePage(page)}
            handleChangeRowsPerPage={e =>
              handleChangePageSize(e?.target?.value)
            }
            handleEdit={item =>
              history.push({
                pathname:
                  "/admin/parameter/client/edit/" + item?.master_client_id,
                state: { customer: item },
              })
            }
            handleView={item =>
              history.push({
                pathname:
                  "/admin/parameter/dsp/detail/" + item?.master_client_id,
                state: { row: item },
              })
            }
            isHaveAction={true}
            isUsingDeleteConfirmation={true}
          />
        </Container>
      )}
    </Page>
  );
}

const CustomDivider = styled(Divider)(() => ({
  marginBottom: 16,
  borderTop: "1px solid #e1e1e1",
}));
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
}));
const columnTable = [
  {
    name: "name",
    title: "DSP",
  },
  {
    name: "color",
    title: "Identity Color",
    renderText: item => (
      <Box
        height="30px"
        width="30px"
        backgroundColor={item}
        border={`solid 0.5px ${item === "#FFF" && "lightgray"}`}
      />
    ),
  },
  {
    name: "person_name",
    title: "Person In Charge",
  },
  {
    name: "person_email",
    title: "Email",
  },
  {
    name: "person_phone",
    title: "Phone Number",
  },
];
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Parameter",
    link: "/admin/parameter/dsp",
  },
  {
    label: "DSP",
    active: true,
  },
];
export default Dsp;
