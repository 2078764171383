import { Box, Button, ButtonGroup } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { globalStyles } from "styles";

const listMenu = [
  {
    id: 0,
    title: "Publisher",
    link: "/admin/parameter/publisher",
  },
  {
    id: 1,
    title: "User",
    link: "/admin/parameter/user-admin",
  },
  {
    id: 2,
    title: "User Publisher",
    link: "/admin/parameter/user-publisher",
  },
  {
    id: 3,
    title: "User Composer",
    link: "/admin/parameter/user-pencipta",
  },
  {
    id: 4,
    title: "Composer",
    link: "/admin/parameter/pencipta-admin",
  },
  {
    id: 5,
    title: "Song",
    link: "/admin/parameter/lagu-admin",
  },
  {
    id: 6,
    title: "DSP",
    link: "/admin/parameter/dsp-admin",
  },
  {
    id: 7,
    title: "Association",
    link: "/admin/parameter/association",
  },
  {
    id: 8,
    title: "Currency",
    link: "/admin/parameter/admin-currency",
  },
  {
    id: 10,
    title: "Country",
    link: "/admin/parameter/admin-country",
  },
  {
    id: 11,
    title: "Revenue Type",
    link: "/admin/parameter/admin-revenue-type",
  },
  {
    id: 12,
    title: "Territory",
    link: "/admin/parameter/territory",
  },
  {
    id: 13,
    title: "Society",
    link: "/admin/parameter/society",
  },
  {
    id: 14,
    title: "User Society",
    link: "/admin/parameter/user-society",
  },
];

function GroupButtonTop({ items, onSelected, selectedPage }) {
  const classes = globalStyles();
  const history = useHistory();
  const usedItems = items?.length ? items : listMenu;
  return (
    <Box
      alignItems="flex-end"
      justifyContent="space-between"
      spacing={3}
      className={clsx(classes.groupButtonRootContainer)}
    >
      <Box className={classes.groupButtonScrollContainer}>
        <ButtonGroup aria-label="outlined primary button group">
          {usedItems?.map(({ title, id, link }) => {
            const selected =
              link === history.location.pathname || selectedPage === id;
            return (
              <Button
                className={
                  selected ? classes.groupButtonSelected : classes?.groupButton
                }
                key={id}
                id={id}
                onClick={() => {
                  if (!link && onSelected) {
                    onSelected(id);
                  } else {
                    history.push(link);
                  }
                }}
              >
                {title}
              </Button>
            );
          })}
        </ButtonGroup>
      </Box>
    </Box>
  );
}

GroupButtonTop.propTypes = {
  items: PropTypes.array,
};

export default GroupButtonTop;
