import _ from "lodash";

export const createSideBarMenu = (items, localSideMenu) => {
  const sideHeaderMenu = items
    .map(item =>
      _.find(localSideMenu[0].items, { title: item.display_groupname })
    )
    .filter(Boolean);
  const newSideHeaderMenu = localSideMenu[0].items
    .map(item => _.find(sideHeaderMenu, { title: item.title }))
    .filter(Boolean);
  return [
    {
      subHeader: "a",
      items: newSideHeaderMenu,
    },
  ];
};

export const getTopMenuForAdmin = (items, menuFromPage) => {
  const predefinedMenus = [
    "Publisher",
    "Currency",
    "User",
    "Publisher User",
    "Composer User",
    "Composer",
    "Song",
    "DSP",
    "Bank",
    "Association",
    "Revenue Type",
    "Country",
    "Exchange Rate",
  ];
  if (!items.masterMenu) {
    return [];
  }
  const availableMenus = predefinedMenus
    .map(menu => _.find(items.masterMenu.child, { display_name: menu }))
    .filter(Boolean);
  return availableMenus
    .map(menuItem => _.find(menuFromPage, { title: menuItem.display_name }))
    .filter(Boolean);
};

export default { createSideBarMenu, getTopMenuForAdmin };
