import {
  Button,
  Container,
  FormControl,
  InputAdornment,
  Box,
  TextField,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTable } from "inno-ui";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import {
  ButtonGroupTop,
  PrimaryButton,
} from "../../../../components/atoms/Button";
import HeaderPage from "../../../../layouts/Header/HeaderPage";
import { Page, SkeletonComponent } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";

const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  content: {
    marginTop: "20px",
  },
  contentAdd: {
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    fontFamily: ["Helvetica Neue"].join(","),
  },
  buttonAdd: {
    height: "44px",
    width: "auto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    backgroundColor: "black",
    color: "white",
    textTransform: "none",
  },
  textAdd: {
    color: "white",
    fontSize: "14px",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",

    "& .MuiOutlinedInput-root": {
      width: "312px",
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
}));

function MasterCountry() {
  const classes = useStyles();

  const masterMenu = useSelector(state => state.masterMenu);

  const [response, setResponse] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [afterCheckTopMenu, setAfterCheckTopMenu] = useState(false);

  const [loadingTable, setLoadingTable] = useState(false);
  const history = useHistory();
  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const getResponse = () => {
    setLoadingTable(true);
    let theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/country/table?page=${page}&per_page=${rowsPerPage}&search=${searchKey}`;
    const headers = {
      headers: {
        Authorization: `Bearer ${theToken}`,
      },
    };

    axios
      .get(url, headers)
      .then(res => {
        setTotalData(res.data.meta.total);
        setResponse(res.data.data);
        setLoadingTable(false);
      })
      .catch(() => {
        setLoadingTable(false);
      });
  };

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const handleChangePage = (event, data) => {
    setPage(data);
  };

  const handleChangeSearch = event => {
    setPage(1);
    setSearchKey(event.target.value);
  };

  const onEdit = item => {
    let payload = {
      country: item,
    };
    history.push({
      pathname: `/admin/parameter/admin-country/edit/${item.id}`,
      state: payload,
    });
  };

  const handleDelete = item => {
    const url = `${hardBaseUrl}/country/delete/${item?.id}`;
    let theToken = localStorage.getItem("token");

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${theToken}`,
      },
    };

    axios
      .delete(url, headers)
      .then(res => {
        if (res.data.meta.http_status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Data has been deleted",
          }).then(result => {
            if (result.isConfirmed === true) {
              getResponse();
            }
          });
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    getResponse();
  }, [rowsPerPage, page, searchKey]);

  useEffect(() => {
    if (masterMenu.masterMenu !== null) {
      setAfterCheckTopMenu(true);
    }
  }, [masterMenu]);

  return (
    <Fragment>
      <Page className={classes.root} title="Country">
        {loadingTable ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <Container maxWidth={false}>
            <HeaderPage
              title="Parameter"
              breadcrumbs={["Parameter", "Country"]}
            />
            {afterCheckTopMenu ? <ButtonGroupTop /> : <>{""}</>}
            <Box className={classes.content}>
              <hr />
              <Box
                display="flex"
                marginTop="20px"
                justifyContent="space-between"
              >
                <FormControl className={classes.margin} variant="outlined">
                  <TextField
                    id="input-with-icon-textfield"
                    variant="outlined"
                    className={classes.inputFields}
                    onChange={handleChangeSearch}
                    placeholder="Search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            alt="Logo"
                            src={
                              require("assets/image-public/images/search.svg")
                                .default
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>

                <PrimaryButton
                  onClick={() =>
                    history.push("/admin/parameter/admin-country/tambah")
                  }
                  label="Add Country"
                />
              </Box>
              <Box marginTop="30px">
                {response && (
                  <InnoTable
                    columns={[
                      {
                        name: "country_iso_code",
                        title: "Country Code",
                        convertFunction: item => {
                          return item;
                        },
                      },
                      {
                        name: "country_name",
                        title: "Country Name",
                        convertFunction: item => {
                          return item;
                        },
                      },
                      {
                        name: "currency",
                        title: "Currency",
                        convertFunction: item => {
                          return item;
                        },
                        renderText: item => {
                          return item.currency_iso_code;
                        },
                      },
                    ]}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleDelete={handleDelete}
                    handleEdit={onEdit}
                    idColumnName={"id"}
                    isLoading={loadingTable}
                    isHaveAction={true}
                    isUsingCheckbox={false}
                    isUsingDeleteConfirmation={true}
                    items={response || []}
                    loadingColor={""}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    totalPage={pagesCount}
                    deleteName="country_iso_code"
                  />
                )}
              </Box>
            </Box>
          </Container>
        )}
      </Page>
    </Fragment>
  );
}

export default MasterCountry;
