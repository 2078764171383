import {
  Container,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  colors,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import NavItem from "../Header/Navbar/NavItem";
import navConfig from "./navConfig";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,

    top: 0,

    height: "calc(100%)",
  },
  navigation: {
    overflow: "auto",
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
  profile: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  badgeDot: {
    height: 9,
    minWidth: 9,
  },
  onlineBadge: {
    backgroundColor: colors.green[600],
  },
  awayBadge: {
    backgroundColor: colors.orange[600],
  },
  busyBadge: {
    backgroundColor: colors.red[600],
  },
  offlineBadge: {
    backgroundColor: colors.grey[300],
  },
  avatar: {
    cursor: "pointer",
    width: 40,
    height: 40,
  },
  details: {
    marginLeft: theme.spacing(2),
  },
  moreButton: {
    marginLeft: "auto",
    color: colors.blueGrey[200],
  },
}));

function renderNavItems({ items, subheader, key, ...rest }) {
  return (
    <List key={key}>
      {subheader && <ListSubheader disableSticky>{subheader}</ListSubheader>}
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={item.href}
        label={item.label}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={item.href}
        label={item.label}
        title={item.title}
      />
    );
  }

  return acc;
}

function NavBar({ openMobile, onMobileClose, className, ...rest }) {
  const classes = useStyles();
  const location = useLocation();

  let pathNow = location.pathname;
  let result = pathNow.substr(1);
  let tempA = result.split("/");

  const auth = useSelector(state => state.auth);
  const [status, setStatus] = useState("online");

  const MpisBottomBar = () => {
    return (
      <div className={classes.profile}>
        <Container
          style={{
            minHeight: "64px",
            display: "flex",
          }}
        >
          <img
            alt="Logo"
            src={require("assets/image-public/images/logos/bottom_logo_sidebar_mpis.svg").default}
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </Container>
      </div>
    );
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <RouterLink to="/">
        <Container
          style={{
            minHeight: "64px",
            display: "flex",
          }}
        >
          <img
            alt="Logo"
            src={require("assets/image-public/images/logos/top_logo_sidebar_mpis.svg").default}
            style={{
              width: "140px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </Container>
      </RouterLink>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {navConfig.map(list =>
          renderNavItems({
            items: list.items,
            subheader: list.subheader,
            pathname: location.pathname,
            key: list.subheader,
          })
        )}
      </nav>
      <Divider className={classes.divider} />
      <MpisBottomBar />
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer,
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
