import { Container, Divider } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  ArrayChip,
  ButtonGroupTop,
  ModalError,
  ModalSuccess,
  ModalWarning,
  Page,
  PrimaryButton,
  PrimaryIconButton,
  SectionLabel,
  SkeletonComponent,
  StatusChip,
} from "components";
import { topMenuButtonMPIS } from "constants";
import { InnoTable } from "inno-ui";
import { HeaderTitle } from "layouts";
import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import EditIcon from "../../../../assets/img/pencilVector.svg";
import RefreshIcon from "../../../../assets/img/refresh-cw.svg";
import DeleteIcon from "../../../../assets/img/trashVector.svg";
import { hardBaseUrl } from "../../../../services/urlConstant";

function RoleUser(props) {
  const classes = globalStyles();
  const history = useHistory();
  const { userRole } = props;
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: "Bearer " + token,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTableAdmin, setDataTableAdmin] = useState([]);
  const [dataTablePublisher, setDataTablePublisher] = useState([]);
  const [dataTableComposer, setDataTableComposer] = useState([]);
  const [dataTableAssociation, setDataTableAssociation] = useState([]);
  const [dataTableSociety, setDataTableSociety] = useState([]);
  const formList = formScaffold({
    dataTableAdmin,
    dataTablePublisher,
    dataTableComposer,
    dataTableAssociation,
    dataTableSociety,
  });

  const handleActivate = item => {
    const { is_active, name } = item;
    ModalWarning(
      `Are you sure to ${is_active ? "deactivate" : "restore"} role ${name} ?`,
      `${is_active ? "Deactivate" : "Restore"} Role`
    ).then(res => res?.isConfirmed && activateRole(item));
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      for (const item of formList) {
        const { roleType } = item;
        const res = await axios.get(`${hardBaseUrl}/role`, {
          headers,
          params: { type: roleType },
        });
        const { data } = res?.data;
        switch (roleType) {
          case "admin":
            setDataTableAdmin(data || []);
            break;
          case "publisher":
            setDataTablePublisher(data || []);
            break;
          case "composer":
            setDataTableComposer(data || []);
            break;
          case "association":
            setDataTableAssociation(data || []);
            break;
          case "society":
            setDataTableSociety(data || []);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const activateRole = async item => {
    const { is_active, role_id } = item;
    try {
      await axios.put(
        `${hardBaseUrl}/role/${role_id}/status`,
        { is_active: !is_active },
        { headers }
      );
      ModalSuccess(
        `Successfully ${is_active ? "Deactivate" : "Restore"} Role`
      ).then(res => res?.isConfirmed && getDataTable());
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };

  useEffect(() => {
    getDataTable();
  }, []);

  return (
    <Page className={classes.root} title="User Role">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Configuration" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop items={topMenuButtonMPIS(userRole)} />
          <Divider className={classes.flatDivider} />
          <Box mt="16px">
            {formList?.map((item, index) => (
              <Fragment key={index}>
                <SectionLabel title={item?.title} subTitle={item?.subTitle} />
                <Box my="16px">
                  <InnoTable
                    isLoading={false}
                    columns={columnTable}
                    items={item?.table || []}
                    isHaveAction
                    renderAction={item => {
                      const {
                        role_id,
                        type,
                        is_active,
                        selected_applications,
                      } = item;
                      return (
                        <Grid container columnSpacing={1}>
                          <Grid item>
                            <PrimaryIconButton
                              tooltipTitle="Edit"
                              icon={<img src={EditIcon} alt="edit" />}
                              onClick={() =>
                                history.push(
                                  `/admin/konfigurasi/role-user/edit/${type}/${role_id}/${selected_applications}`
                                )
                              }
                            />
                          </Grid>
                          <Grid item>
                            <PrimaryIconButton
                              tooltipTitle={
                                is_active ? "Deactivate" : "Restore"
                              }
                              icon={
                                is_active ? (
                                  <img src={DeleteIcon} alt="update-icon" />
                                ) : (
                                  <img src={RefreshIcon} alt="refresh" />
                                )
                              }
                              onClick={() => handleActivate(item)}
                            />
                          </Grid>
                        </Grid>
                      );
                    }}
                  />
                </Box>
                <Grid container justifyContent="right">
                  <PrimaryButton
                    label={`Add ${item?.title}`}
                    onClick={() =>
                      history.push(
                        `/admin/konfigurasi/role-user/add/${item?.roleType}`
                      )
                    }
                    startIcon={<Add />}
                    width={235}
                  />
                </Grid>
                {index < formList?.length - 1 && (
                  <Divider className={classes.divider} />
                )}
              </Fragment>
            ))}
          </Box>
        </Container>
      )}
    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Configuration",
    link: "/admin/konfigurasi/role-user",
  },
  {
    label: "User Role",
    active: true,
  },
];
const formScaffold = ({
  dataTableAdmin,
  dataTablePublisher,
  dataTableComposer,
  dataTableAssociation,
  dataTableSociety,
}) => [
  {
    title: "User Role",
    subTitle:
      "Displays a list of roles available in the MPIS application. Roles are made for the needs of grouping users based on work functions.",
    table: dataTableAdmin,
    roleType: "admin",
  },
  {
    title: "Publisher User Role",
    subTitle:
      "Displays a list of roles available in the MPIS application. Roles are made for the needs of grouping users based on work functions.",
    table: dataTablePublisher,
    roleType: "publisher",
  },
  {
    title: "Composer User Role",
    subTitle:
      "Displays a list of roles available in the MPIS application. Roles are created for the needs of grouping users based on the work function of the composer user.",
    table: dataTableComposer,
    roleType: "composer",
  },
  {
    title: "Association User Role",
    subTitle: "Displays a list of roles available in the MPIS association.",
    table: dataTableAssociation,
    roleType: "association",
  },
  {
    title: "Society User Role",
    subTitle: "Displays a list of roles available in the MPIS society user",
    table: dataTableSociety,
    roleType: "society",
  },
];
const columnTable = [
  {
    name: "is_active",
    title: "Status",
    renderText: item => (
      <StatusChip
        label={item ? "Active" : "Inactive"}
        type={item ? "success" : ""}
      />
    ),
  },
  {
    name: "name",
    title: "Role Name",
  },
  {
    name: "permissions",
    title: "Role Menu",
    renderText: item => <ArrayChip list={item} />,
  },
];
export default RoleUser;
