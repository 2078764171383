import { Container, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { InnoTable } from "inno-ui";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import {
  AutoCompleteComponent,
  DateRangePicker,
  DateTimeDisplay,
  ModalError,
  Page,
  SkeletonComponent,
} from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { HeaderTitle } from "layouts";
import { Typography, Grid, styled } from "@mui/material";
import { debounce } from "lodash";
import { getErrors } from "utils";
import { getTotalPage } from "lib";
import axios from "axios";
import { topMenuButtonMPIS, topMenuButtonCaris } from "constants";
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  textField: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    "& .MuiOutlinedInput-root": {
      width: "312px",
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
    fontStyle: "normal",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
    fontStyle: "normal",
  },
  tableRow: {
    height: 20,
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  dropdown: {
    color: "black",
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  selectAction: {
    "& .MuiInputBase-root": {
      height: 44,
    },
  },
  selectDate: {
    width: "268px",
    height: "44px",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    marginLeft: "30px",
  },
}));

function Aktifitas({ userRole }) {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const typeWeb = localStorage.getItem("typeWeb");

  const [loadingPageSkeleton, setLoadingPageSkeleton] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    search: "",
    action_id: 0,
    start_date: moment(),
    end_date: moment(),
  });
  const [optionAction, setOptionAction] = useState([]);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleResetPage = () => handleChangeQueryParams(1, "page");
  const handleChangeRowsPerPage = event => {
    const { value } = event?.target;
    handleChangeQueryParams(value, "size");
    handleResetPage();
  };
  const getActionList = async () => {
    setLoadingPageSkeleton(true);
    try {
      const res = await axios.get(`${hardBaseUrl}/log/log-action`, { headers });
      const modifiedData = res?.data?.data?.map(item => ({
        label: item.action_name,
        id: item.log_action_id,
      }));
      setOptionAction(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingTable(false);
      setLoadingPageSkeleton(false);
    }
  };
  const getDataTable = async () => {
    setLoadingTable(true);
    const params = {
      ...queryParams,
      start_date: formatDateFilter(queryParams?.start_date),
      end_date: formatDateFilter(queryParams?.end_date),
    };
    try {
      const res = await axios.get(`${hardBaseUrl}/publisher/log`, {
        headers,
        params,
      });
      const { data, meta } = res?.data;
      setDataTable(data);
      const pageCount = getTotalPage(meta?.total, queryParams?.size);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingTable(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );

  useEffect(() => {
    getActionList();
  }, []);
  useEffect(() => {
    debounceDataTable();
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Activity">
      {loadingPageSkeleton ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Configuration" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop
            items={
              typeWeb === "mpis" ? topMenuButtonMPIS(userRole) : topMenuButtonCaris
            }
          />
          <Divider className={classes.divider} />
          <TableTitle>Activity</TableTitle>
          <Typography variant="body2" mb={2}>
            Displays a list of user activity logs.
          </Typography>
          <Grid container justifyContent="flex-end" columnSpacing={2} my={3}>
            <Grid item>
              <AutoCompleteComponent
                options={optionAction}
                label="Select Action"
                value={
                  optionAction.find(
                    option => option.id === queryParams?.action_id
                  ) || null
                }
                onChange={value => handleChangeQueryParams(value, "action_id")}
                size="small"
              />
            </Grid>
            <Grid item>
              <DateRangePicker
                label="Date Range"
                startDate={queryParams?.start_date}
                handleChangeStartDate={date =>
                  handleChangeQueryParams(date, "start_date")
                }
                endDate={queryParams?.end_date}
                handleChangeEndDate={date =>
                  handleChangeQueryParams(date, "end_date")
                }
              />
            </Grid>
          </Grid>
          <InnoTable
            columns={columnTable}
            handleChangePage={(_, data) =>
              handleChangeQueryParams(data, "page")
            }
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isLoading={loadingTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tableTotalPage}
          />
        </Container>
      )}
    </Page>
  );
}

const formatDateFilter = date => moment(date).format("YYYY-MM-DD");
const TableTitle = styled(Typography)(() => ({
  fontSize: 18,
  fontWeight: 600,
}));
const TableBody = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 500,
}));

const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Configuration",
    link: "/admin/konfigurasi/aktifitas",
  },
  {
    label: "Activty",
    active: true,
  },
];
const columnTable = [
  {
    name: "all",
    title: "Date",
    renderText: item => (
      <DateTimeDisplay
        date={moment(
          `${item?.date} ${item?.time}`,
          "D-MMM-YYYY hh:mm:ss A"
        ).format()}
      />
    ),
  },
  {
    name: "all",
    title: "Account",
    renderText: item => (
      <div>
        <TableBody>{item?.created_by_name}</TableBody>
        <Typography variant="body2">{item?.created_by_email}</Typography>
      </div>
    ),
  },
  {
    name: "action",
    title: "Action",
  },
  {
    name: "content",
    title: "Description",
  },
];
export default Aktifitas;
