import {
  Breadcrumbs,
  Button,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import { InnoTable } from "inno-ui";
import { Fragment } from "react";
import { useHistory } from "react-router";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import { Page } from "components";
const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  content: {
    marginTop: "20px",
  },
  contentAdd: {
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    fontFamily: ["Helvetica Neue"].join(","),
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    "& .MuiOutlinedInput-root": {
      width: "312px",
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
}));
const columns = [
  {
    name: "code",
    title: "Code",
  },
  {
    name: "message_content",
    title: "Message Content",
  },
  {
    name: "message_type",
    title: "Message Type",
  },
  {
    name: "all",
    title: "Status",
    renderText: ({ status }) => (
      <Switch
        checked={status}
        color="secondary"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    ),
  },
];
const mockData = [
  {
    id: 1,
    code: "msg1236",
    message_content: "This is message content of some feature",
    message_type: "Alert Error Message",
    status: true,
  },
  {
    id: 2,
    code: "msg1237",
    message_content: "This is message content of some feature",
    message_type: "Alert Error Message",
    status: true,
  },
  {
    id: 3,
    code: "msg1238",
    message_content: "This is message content of some feature",
    message_type: "Alert Error Message",
    status: true,
  },
  {
    id: 4,
    code: "msg1239",
    message_content: "This is message content of some feature",
    message_type: "Alert Error Message",
    status: false,
  },
];

function AdminMessage() {
  const classes = useStyles();
  const history = useHistory();
  const topMenuButton = [
    {
      id: 0,
      title: "System",
      link: "/admin/konfigurasi/sistem",
      selected: false,
    },
    {
      id: 1,
      title: "Notification Template",
      link: "/admin/konfigurasi/template-notifikasi",
      selected: false,
    },
    {
      id: 2,
      title: "Tax",
      link: "/admin/konfigurasi/pajak",
      selected: false,
    },
    {
      id: 3,
      title: "Role",
      link: "/admin/konfigurasi/role-user",
      selected: false,
    },
    {
      id: 4,
      title: "Activity",
      link: "/admin/konfigurasi/aktifitas-admin",
      selected: false,
    },
    {
      id: 5,
      title: "Billing Configuration",
      link: "/admin/konfigurasi/billing-configuration",
      selected: false,
    },
    {
      id: 6,
      title: "DSP Type Revenue",
      link: "/admin/konfigurasi/dsp-type-revenue",
      selected: true,
    },
    {
      id: 7,
      title: "Message",
      link: "/admin/konfigurasi/message",
      selected: true,
    },
    {
      id: 8,
      title: "Announcement Management",
      link: "/admin/konfigurasi/announcement-management",
      selected: false,
    },
  ];
  return (
    <Fragment>
      <Page className={classes.root} title="Message">
        <Container maxWidth={false}>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={fontInter}>
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: 700,
                    lineHeight: "32px",
                  }}
                >
                  Configuration
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <ThemeProvider theme={fontInter}>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      color: "#111827",
                    }}
                  >
                    Configuration
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={fontInter}>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      color: "#687083",
                    }}
                  >
                    Message
                  </Typography>
                </ThemeProvider>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <ButtonGroupTop items={topMenuButton} />
          <div className={classes.content}>
            <hr />
            <div style={{ display: "flex", marginTop: "20px" }}>
              <div>
                <Button
                  href="/admin/konfigurasi/message/add"
                  variant="contained"
                  style={{
                    height: "44px",
                    width: "auto",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "24px",
                    backgroundColor: "black",
                    color: "white",
                    textTransform: "none",
                  }}
                  startIcon={<AddIcon />}
                >
                  <ThemeProvider theme={fontInter}>
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "14px",
                      }}
                    >
                      Add Message
                    </Typography>
                  </ThemeProvider>
                </Button>
              </div>
              <FormControl className={classes.margin} variant="outlined">
                <TextField
                  id="input-with-icon-textfield"
                  variant="outlined"
                  style={{
                    marginLeft: "8px",
                  }}
                  className={classes.inputFields}
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          alt="Logo"
                          src={
                            require("assets/image-public/images/search.svg")
                              .default
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>
            <div style={{ marginTop: "30px" }}>
              <InnoTable
                columns={columns}
                handleEdit={({ id, ...item }) =>
                  history.push({
                    pathname: `/admin/konfigurasi/message/edit/${id}`,
                    state: { id, ...item },
                  })
                }
                idColumnName={"id"}
                isHaveAction={true}
                items={mockData}
                isLoading={false}
              />
            </div>
          </div>
        </Container>
      </Page>
    </Fragment>
  );
}
export default AdminMessage;
