import { Container, Divider } from "@material-ui/core";
import { Box, Chip, Grid, styled } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  FormLabel,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SectionLabel,
  SkeletonComponent,
  TextInput,
} from "components";
import { topMenuButtonMPIS } from "constants";
import { HeaderTitle } from "layouts";
import { useEffect, useState } from "react";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

function TemplateNotifikasi(props) {
  const classes = globalStyles();
  const { userRole } = props;
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [notificationList, setNotificationList] = useState([]);

  const handleChangePayload = (value, key, id) => {
    setNotificationList(currentState =>
      currentState.map(item =>
        item.notification_template_id === id ? { ...item, [key]: value } : item
      )
    );
  };

  const getDetail = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/notification-template`, {
        headers,
      });
      setNotificationList(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleSaveContent = async item => {
    const { notification_template_id, content, reminder_time } = item;
    try {
      setLoadingPage(true);
      await axios.put(
        `${hardBaseUrl}/notification-template/${notification_template_id}`,
        { content, reminder_time },
        { headers }
      );
      ModalSuccess("Successfully change the notification template");
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <Page className={classes.root} title="Notification Template">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <>
          <Container maxWidth={false}>
            <HeaderTitle
              title="Configuration"
              breadcrumbData={breadcrumbData}
            />
            <ButtonGroupTop items={topMenuButtonMPIS(userRole)} />
            <Divider className={classes.flatDivider} />
            {notificationList?.length > 0 &&
              notificationList.map((item, index) => (
                <Box key={index} maxWidth="60%" mt="16px">
                  <SectionLabel
                    title={item?.title}
                    subTitle={item.description}
                  />
                  <Box
                    border="1px solid #E4E7EB"
                    borderRadius="8px"
                    padding="24px"
                    my="16px"
                  >
                    <Box mb="16px">
                      <FormLabel label="Content" />
                      <TextInput
                        value={item?.content}
                        onChange={e =>
                          handleChangePayload(
                            e?.target?.value,
                            "content",
                            item?.notification_template_id
                          )
                        }
                        multiline
                        rows={4}
                      />
                    </Box>
                    <FormLabel label="Remider" />
                    <Grid container columnSpacing={1}>
                      {optionPeriod.map((data, index) => (
                        <Grid item key={index}>
                          <PeriodChip
                            label={data?.label}
                            variant={
                              data?.value === item?.reminder_time
                                ? "filled"
                                : "outlined"
                            }
                            onClick={() =>
                              handleChangePayload(
                                data?.value,
                                "reminder_time",
                                item?.notification_template_id
                              )
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  <Grid container justifyContent="right" my="16px">
                    <PrimaryButton
                      label="Change Content"
                      onClick={() => handleSaveContent(item)}
                    />
                  </Grid>
                  {index !== notificationList?.length - 1 && (
                    <Divider className={classes.divider} />
                  )}
                </Box>
              ))}
          </Container>
        </>
      )}
    </Page>
  );
}

const PeriodChip = styled(Chip)(({ variant }) => ({
  ...(variant === "filled" && {
    "&.MuiButtonBase-root": {
      backgroundColor: "#364052",
      color: "white",
    },
  }),
}));
const breadcrumbData = [
  {
    label: "Configuration",
    link: "/admin/konfigurasi/template-notifikasi",
  },
  {
    label: "Notification Template",
    active: true,
  },
];
const optionPeriod = [
  { key: 0, label: "1 month", value: 30 },
  { key: 1, label: "2 month", value: 60 },
  { key: 2, label: "3 month", value: 90 },
];

export default TemplateNotifikasi;
