export const topMenuParameter = [
  {
    id: 0,
    title: "Composer/Author",
    link: "/admin/parameter/pencipta",
    selected: false,
  },
  {
    id: 1,
    title: "Song",
    link: "/admin/parameter/lagu",
    selected: false,
  },
  {
    id: 2,
    title: "Performer",
    link: "/admin/parameter/performer",
    selected: false,
  },
  {
    id: 3,
    title: "Client",
    link: "/admin/parameter/dsp",
    selected: true,
  },
  {
    id: 4,
    title: "User",
    link: "/admin/parameter/user",
    selected: false,
  },
  {
    id: 5,
    title: "Agent",
    link: "/admin/parameter/agent",
    selected: false,
  },
  {
    id: 6,
    title: "Group",
    link: "/admin/parameter/group",
    selected: false,
  },
  {
    id: 7,
    title: "Client Classification",
    link: "/admin/parameter/client-classification",
    selected: false,
  },
];
