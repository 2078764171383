import { Container, Divider } from "@material-ui/core";
import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import {
  ModalError,
  Page,
  PrimaryButton,
  SongUsageTrendChart,
  SongUsageTrendSummarytCard,
  TableCellMultipleRow,
} from "components";
import { InnoTable } from "inno-ui";
import { HeaderTitle } from "layouts";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

const DetailReviewLagu = () => {
  const classes = globalStyles();
  const history = useHistory();
  const { state } = useLocation();
  const { song, queryParams } = state;
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const params = {
    year: queryParams?.year,
    dsp_id: queryParams?.dsp_id,
    publisher_id: queryParams?.publisher_id,
    ...(queryParams?.original_publisher_id && {
      original_publisher_id: queryParams?.original_publisher_id,
    }),
    composer_id: queryParams?.composer_id,
    month: queryParams?.month,
  };

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [dataTable, setDataTable] = useState([]);

  const [chartReport, setChartReport] = useState({
    revenue: 0,
    traffic: 0,
    month: "",
    totalRevenue: 0,
    totalTraffic: 0,
  });
  const [songDetail, setSongDetail] = useState({
    composers: [],
    iswc: "",
    publisherName: "",
  });
  const [csvArray, setCsvArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeChartReport = (value, key) => {
    setChartReport(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const onClickChartAxis = data => {
    if (chartData?.datasets.length > 0) {
      const { dataIndex, axisValue } = data;
      const monthName = moment(axisValue, "MMM YY").format("MMMM");
      const monthTotalTraffic = chartData?.datasets[0]?.listener?.[dataIndex];
      const monthTotalRevenue = chartData?.datasets[0]?.data?.[dataIndex];

      handleChangeChartReport(monthTotalTraffic, "traffic");
      handleChangeChartReport(monthTotalRevenue, "revenue");
      handleChangeChartReport(monthName, "month");
    }
  };

  const getResponse = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${hardBaseUrl}/publisher/song-usage/${song?.song_id}`,
        { headers, params }
      );
      const { data } = res?.data;
      const { usages } = data;
      setSongDetail({
        composers: data?.composers,
        iswc: data?.song?.iswc_code || "-",
        publisherName: data?.publisher?.name,
      });
      setChartReport(currentState => ({
        ...currentState,
        totalRevenue: data?.total_royalty,
        totalTraffic: data?.total_listener,
      }));
      setDataTable(usages);

      const labels = usages?.map(item => moment(item.date).format("MMM YY"));
      const datasets = [
        {
          label: "",
          data: usages?.map(chartItem => chartItem?.base_currency_revenue),
          listener: usages?.map(chartItem => chartItem?.listener),
          color: "#9545EB",
          showMark: false,
        },
      ];
      setChartData({
        labels,
        datasets,
      });
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setIsLoading(false);
    }
  };
  const getCsvArray = () => {
    const url = `${hardBaseUrl}/publisher/song-usage/${song.song_id}/download`;
    axios
      .get(url, { headers, params })
      .then(res => {
        if (res.data.data !== null) {
          const reConstructData = (res?.data?.data || [])?.map(item => ({
            "Song Title": item?.SongTitle,
            ISWC: item?.ISWC,
            Publisher: item?.Publisher,
            Composer: item?.Composer,
            Month: item?.Month,
            Year: item?.Year,
            Traffic: item?.Traffic,
            Revenue: item?.OriginalCurrencyRevenue,
            ...(isCaris && {
              "Composer Revenue": item?.BaseCurrencyComposerRevenue,
              "Publisher Revenue": item?.BaseCurrencyPublisherRevenue,
            }),
            "Product Type": item?.ProductType,
          }));
          setCsvArray(reConstructData);
        }
      })
      .catch(err => new Error(err));
  };

  useEffect(() => {
    getResponse();
    getCsvArray();
  }, []);

  return (
    <div>
      <Page className={classes.root} title="Song Usage Detail">
        <Container maxWidth={false}>
          <HeaderTitle
            title={
              <Box>
                <Typography fontSize="24px">
                  Song Title:{" "}
                  <Typography component="span" fontSize="24px" fontWeight={700}>
                    {song?.title}
                  </Typography>
                </Typography>
                <Typography fontWeight={400} fontSize="18px">
                  {`Year of Song Usage: ${queryParams?.year}`}
                </Typography>
              </Box>
            }
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Divider className={classes.divider} />
          <Box border="1px solid #D1D5DC" borderRadius="6px" p="24px">
            <div>
              {songDetailList(songDetail)?.map((item, index) => (
                <Grid container key={index}>
                  <Grid item xs={2}>
                    <Typography>{item?.title}</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Box display="flex">
                      {item?.title === "Composer"
                        ? (item?.name || [])?.map((composer, index) => (
                            <Typography
                              key={index}
                              fontWeight={composer?.is_on_this_publisher && 700}
                            >
                              {`${index > 0 ? "," : ""} ${composer?.name}`}
                            </Typography>
                          ))
                        : item?.name || "-"}
                    </Box>
                  </Grid>
                </Grid>
              ))}
            </div>
          </Box>
          <Typography fontSize="24px" fontWeight={500} my="24px">
            Trend of Song Usage
          </Typography>

          <Box py="20px" border="1px solid #D1D5DC" borderRadius="6px">
            <SongUsageTrendChart
              chartData={chartData}
              onAxisClick={(_, data) => onClickChartAxis(data)}
            />
          </Box>
          <SongUsageTrendSummarytCard chartReport={chartReport} />

          <Grid container columnSpacing={2} my={2} justifyContent="flex-end">
            <Grid item>
              <PrimaryButton
                label="Download PDF"
                onClick={() =>
                  history.push({
                    pathname: "/admin/review-lagu/detail/print-report",
                    state: {
                      song,
                      queryParams,
                      songDetailList: songDetailList(songDetail),
                      chartData,
                      chartReport,
                      dataTable,
                    },
                  })
                }
              />
            </Grid>
            <Grid item>
              <CSVLink data={csvArray} filename="song-detail.csv">
                <PrimaryButton label="Download CSV" />
              </CSVLink>
            </Grid>
          </Grid>
          <InnoTable
            columns={columnTable}
            items={dataTable}
            isLoading={isLoading}
          />
        </Container>
      </Page>
    </div>
  );
};

const typeWeb = localStorage.getItem("typeWeb");
const isCaris = typeWeb === "caris";
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Song Usage",
    link: "/admin/review-lagu",
  },
  {
    label: "Song Detail",
    active: true,
  },
];
const columnTable = [
  {
    name: "date",
    title: "Month",
    renderText: value => moment(value).format("MMMM"),
  },
  {
    name: "date",
    title: "Year",
    renderText: value => moment(value).format("YYYY"),
  },
  {
    name: "summaries",
    title: "Traffic",
    headerAlign: "right",
    renderText: item => (
      <TableCellMultipleRow list={item} itemKey="listener" type="number" />
    ),
  },
  {
    name: "summaries",
    title: "Revenue",
    headerAlign: "right",
    renderText: item => (
      <TableCellMultipleRow
        list={item}
        itemKey="base_currency_revenue"
        type="number"
        prefix="Rp"
      />
    ),
  },
  ...(isCaris
    ? [
        {
          name: "summaries",
          title: "Composer Revenue",
          headerAlign: "right",
          renderText: item => (
            <TableCellMultipleRow
              list={item}
              itemKey="base_currency_composer_revenue"
              type="number"
              prefix="Rp"
            />
          ),
        },
        {
          name: "summaries",
          title: "Publisher Revenue",
          headerAlign: "right",
          renderText: item => (
            <TableCellMultipleRow
              list={item}
              itemKey="base_currency_publisher_revenue"
              type="number"
              prefix="Rp"
            />
          ),
        },
      ]
    : []),
  {
    name: "summaries",
    title: "Product Type",
    renderText: item => (
      <TableCellMultipleRow list={item} itemKey="product_type" />
    ),
  },
];
const songDetailList = songDetail => [
  {
    title: "ISWC Code",
    name: songDetail?.iswc,
  },
  {
    title: "Publisher",
    name: songDetail?.publisherName,
  },
  {
    title: "Composer",
    name: songDetail?.composers,
  },
];

export default DetailReviewLagu;
