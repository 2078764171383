import {
  Breadcrumbs,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Box,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import { Page, SkeletonComponent, PrimaryButton } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { topMenuButtonMPIS, topMenuButtonCaris } from "constants";
const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const ContractReminder = () => {
  const history = useHistory();
  const initIndex = 0;
  const typeWeb = localStorage.getItem("typeWeb");
  const [contractCheck, setContractCheck] = useState(false);
  const [loadingPageSkeleton, setLoadingPageSkeleton] = useState(false);
  const [firstReminder, setFirstReminder] = useState(0);
  const [secondReminder, setSecondReminder] = useState(0);
  const [contractReminderId, setContractReminderId] = useState(0);
  const [errorReminder1, setErrorReminder1] = useState(false);
  const [errorReminder2, setErrorReminder2] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    reminder1: "",
    reminder2: "",
  });
  const [invoiceReminderCheck, setInvoiceReminderCheck] = useState(false);
  const [invoiceValue, setInvoiceValue] = useState(0);

  const handleChangeInvoice = event => {
    setInvoiceValue(event.target.value);
  };

  const handleInvoiceReminderCheck = event => {
    setInvoiceReminderCheck(event.target.checked);
  };

  const handleOnSelectedButton = value => {
    history.push(value.data[value.newButtonSelected].link);
  };
  const handleChangeContractCheck = event => {
    setContractCheck(event.target.checked);
  };

  const handleChangeReminder1 = event => {
    if (event.target.name === "firstReminder") {
      setFirstReminder(event.target.value);
      if (event.target.value < secondReminder) {
        setErrorMessage({
          ...errorMessage,
          reminder1: "First reminder cannot be less than second reminder",
        });
        setErrorReminder1(true);
      } else {
        setErrorMessage({
          ...errorMessage,
          reminder1: "",
        });
        setErrorReminder1(false);
      }
    }
  };

  const handleChangeReminder2 = event => {
    if (event.target.name === "secondReminder") {
      setSecondReminder(event.target.value);
      if (event.target.value > firstReminder) {
        setErrorMessage({
          ...errorMessage,
          reminder2: "Second reminder cannot be greater than first reminder",
        });
        setErrorReminder2(true);
      } else {
        setErrorMessage({
          ...errorMessage,
          reminder2: "",
        });
        setErrorReminder2(false);
      }
    }
  };

  const getDetailContractReminder = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/config/contract/reminder`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setContractCheck(res.data.data.is_active_flag);
        setFirstReminder(res.data.data.reminder_1);
        setSecondReminder(res.data.data.reminder_2);
        setContractReminderId(res.data.data.contract_reminder_id);
      })
      .catch(err => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.errors[0].message,
        });
      });
  };

  useEffect(() => {
    getDetailContractReminder();
  }, []);

  const handleSaveContractReminder = () => {
    if (errorReminder1 || errorReminder2 === false) {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/publisher/config/contract/reminder`;
      const data = {
        is_active_flag: contractCheck,
        reminder_1: Number(firstReminder),
        reminder_2: Number(secondReminder),
        contract_reminder_id: contractReminderId,
      };
      axios
        .post(url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          Swal.fire({
            title: "Success",
            text: "Contract Reminder has been updated",
            icon: "success",
            confirmButtonText: "OK",
          }).then(result => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.errors[0].message,
          });
        });
    }
  };

  const handleSaveInvoiceReminder = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/invoice-reminder`;
    const data = {
      is_active_flag: invoiceReminderCheck,
      value: Number(invoiceValue),
    };

    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "Invoice Reminder has been updated",
          icon: "success",
          confirmButtonText: "OK",
        }).then(result => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      })
      .catch(err => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.errors[0].message,
        });
      });
  };

  const getResponseInvoiceReminder = () => {
    setLoadingPageSkeleton(true);
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/invoice-reminder`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setInvoiceReminderCheck(res.data.data.is_active_flag);
        setInvoiceValue(res.data.data.value);
        setLoadingPageSkeleton(false);
      })
      .catch(err => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.errors[0].message,
        });
        setLoadingPageSkeleton(false);
      });
  };

  useEffect(() => {
    getResponseInvoiceReminder();
  }, []);

  const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    divider: {
      margin: theme.spacing(2, 0),
      borderTop: "1px solid #e1e1e1",
    },
    results: {
      marginTop: theme.spacing(3),
    },
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    button: {
      padding: "10px 28px 10px 28px",
      backgroundColor: "black",
      color: "white",
      width: "213px",
      height: "40px",
      borderRadius: "6px",
      textTransform: "none",
      whiteSpace: "nowrap",
      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    buttonSave: {
      textTransform: "none",
      backgroundColor: "black",
      color: "white",
      width: "129px",
      height: "40px",
      marginBottom: "20px",
    },
    subtitle: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "28px",
    },
    textSave: {
      color: "white",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
    },
    breadcrumbs: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#111827",
    },
    breadCrumbsActive: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#687083",
    },
    selectPadding: {
      height: "80px",
      "& .MuiInputBase-fullWidth": {
        height: "55px",
      },
    },
    rootSwitch: {
      width: "40px",
      height: "20px",
      padding: "0px",
      borderRadius: "10px",

      position: "relative",
    },
    switchBase: {
      padding: "10px",
      color: "white",
      "&.Mui-checked": {},
      "&.Mui-checked + $track": {
        border: "none",
        color: "white",
        backgroundColor: "black",
        opacity: 1,
      },
    },
    checked: {},
    thumb: {
      color: "white",
      position: "absolute",
      width: "16px",
      height: "16px",
    },
    track: {
      backgroundColor: "#E4E7EB",
      borderRadius: "25px",
      border: "0px solid #606060",
      height: "auto",
      opacity: 1,
    },
    font24: {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "32px",
    },
    cardParent: {
      border: "1px solid #D1D5DC",
      borderRadius: "6px",
      boxShadow: "none",
    },
    textStyle: {
      marginTop: "5px",
      color: "#8f8f8f",
      fontSize: "14px",
    },
    colorWhite: {
      color: "white",
    },
    customColor: {
      color: "#111827",
    },
    pReminder: {
      marginTop: "15px",
      marginLeft: "-10px",
    },
    colorRed: {
      color: "red",
    },
  }));
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Reminder">
      {loadingPageSkeleton ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={theme}>
                <Typography className={classes.font24}>
                  Configuration
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography className={classes.breadCrumbs}>Home</Typography>

                <Typography className={classes.breadCrumbs}>
                  Configuration
                </Typography>

                <Typography className={classes.breadCrumbsActive}>
                  Contract
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <ButtonGroupTop
            items={
              typeWeb === "mpis" ? topMenuButtonMPIS() : topMenuButtonCaris
            }
            selectedButton={handleOnSelectedButton}
            selectedIndex={initIndex}
          />
          <Divider className={classes.divider} />
          <Grid container>
            <Grid item xs={4} md={4} sm={4} lg={8} xl={8}>
              <ThemeProvider theme={theme}>
                <Typography variant="h3" className={classes.subtitle}>
                  Composer/Author Contract Configuration
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p className={classes.textStyle}>
                  Feature to settings contract’s reminder via email
                </p>
              </ThemeProvider>

              <ThemeProvider theme={theme}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Checkbox
                      checked={contractCheck}
                      onChange={handleChangeContractCheck}
                      className={classes.customColor}
                    />
                  </Grid>
                  <Grid item>
                    <p className={classes.pReminder}>Use Contract Reminder</p>
                  </Grid>
                </Grid>
              </ThemeProvider>
              {contractCheck && (
                <Grid container spacing={1}>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <ThemeProvider theme={theme}>
                      <label>First Reminder</label>
                    </ThemeProvider>

                    <TextField
                      defaultValue={0}
                      variant="outlined"
                      onChange={e => handleChangeReminder1(e)}
                      value={firstReminder}
                      type="number"
                      name="firstReminder"
                      id="formatted-numberformat-input"
                      margin="dense"
                      fullWidth={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {"Day"}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <small>
                      {firstReminder} days before the contract expires
                    </small>
                    {errorReminder1 ? (
                      <small>
                        <p className={classes.colorRed}>
                          {errorMessage.reminder1}
                        </p>
                      </small>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <div>
                      <ThemeProvider theme={theme}>
                        <label>Second Reminder</label>
                      </ThemeProvider>

                      <TextField
                        defaultValue={0}
                        variant="outlined"
                        onChange={e => handleChangeReminder2(e)}
                        value={secondReminder}
                        type="number"
                        name="secondReminder"
                        id="formatted-numberformat-input"
                        margin="dense"
                        fullWidth={true}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {"Day"}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <small>
                        {secondReminder} days before the contract expires
                      </small>
                      {errorReminder2 ? (
                        <small>
                          <p className={classes.colorRed}>
                            {errorMessage.reminder2}
                          </p>
                        </small>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Grid>
                  <Grid item md={12}>
                    <Box justifyContent="flex-end" display="flex">
                      <PrimaryButton
                        label="Save"
                        onClick={handleSaveContractReminder}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={4} md={4} sm={4} lg={8} xl={8}>
              <ThemeProvider theme={theme}>
                <Typography variant="h3" className={classes.subtitle}>
                  Invoice Reminder Configuration
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p className={classes.textStyle}>
                  Feature to settings month before invoice due date
                </p>
              </ThemeProvider>

              <ThemeProvider theme={theme}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    {}

                    <Checkbox
                      checked={invoiceReminderCheck}
                      onChange={handleInvoiceReminderCheck}
                      className={classes.customColor}
                    />
                  </Grid>
                  <Grid item>
                    <p className={classes.pReminder}>Use Invoice Reminder</p>
                  </Grid>
                </Grid>
              </ThemeProvider>
              {invoiceReminderCheck && (
                <Grid container spacing={1}>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <div>
                      <ThemeProvider theme={theme}>
                        <label htmlFor="invoice">First Reminder</label>
                      </ThemeProvider>
                      <br />

                      <FormControl variant="outlined" fullWidth={true}>
                        <Select
                          labelId="invoice"
                          value={invoiceValue}
                          onChange={handleChangeInvoice}
                        >
                          <MenuItem value={0} selected>
                            <em>Select a time range</em>
                          </MenuItem>
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                        </Select>
                      </FormControl>
                      <small>
                        {invoiceValue} Month before the contract expires
                      </small>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={12} sm={12} lg={12} xl={12}>
                    <Box justifyContent="flex-end" display="flex">
                      <PrimaryButton
                        label="Save"
                        onClick={handleSaveInvoiceReminder}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default ContractReminder;
