import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Snackbar,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {
  default as DialogTitle,
  default as IconButton,
} from "@material-ui/core/DialogTitle";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import HeaderPage from "../../../../layouts/Header/HeaderPage";
import { Page, SkeletonComponent } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import Results from "./Results";
import { getTopMenuForAdmin } from "../../../../utils";
import { topMenuButtonMPIS } from "constants";
import { HeaderTitle } from "layouts";

const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  inputFields: {
    "& .MuiOutlinedInput-input": {
      padding: "0px",
      height: "44px",
      width: "268px",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const img = {
  display: "block",
  width: "auto",
  height: "100%",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: "6px",
};

function Bank(props) {
  const { userRole } = props;
  const classes = useStyles();
  const history = useHistory();
  const masterMenu = useSelector(state => state.masterMenu);
  const inputFile = useRef(null);
  const [searchKey, setSearchKey] = useState("");

  const [loadingResult, setLoadingResult] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [typeSnackbar] = useState("success");
  const [message] = useState("This is a message!");
  const [customers, setCustomers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageApi, setCurrentPageApi] = useState(1);
  const [totalSizeApi, setTotalSizeApi] = useState(10);
  const [openModalTambah, setOpenModalTambah] = useState(false);
  const [newNamaBank, setNewNamaBank] = useState("");
  const [newKodeBank, setNewKodeBank] = useState("");
  const [newLogoBank, setNewLogoBank] = useState("");
  const [newDataLogoBank, setNewDataLogoBank] = useState(null);
  const [reloadList, setReloadList] = useState(false);
  const pagesCount = Math.ceil(totalData / rowsPerPage);

  useEffect(() => {
    setLoadingResult(true);
    const fetchUsers = async () => {
      try {
        let baseOfUrl = hardBaseUrl;
        let theToken = localStorage.getItem("token");

        const url =
          baseOfUrl +
          "/bank?page=" +
          currentPageApi +
          "&size=" +
          totalSizeApi +
          "&search=" +
          searchKey;

        const options = {
          headers: {
            Authorization: "Bearer " + theToken,
          },
        };
        const response = await axios.get(url, options);

        setCustomers(response.data.data);
        setTotalData(response.data.meta.total);
        setCurrentPage(response.data.meta.page);
        setLoadingResult(false);
        setReloadList(false);
      } catch (error) {
        if (error.response.status === 404) {
        }
      }
    };
    fetchUsers();
  }, [searchKey, currentPageApi, totalSizeApi, page, reloadList]);

  const handleOnSelectedButton = value => {
    history.push(value.data[value.newButtonSelected].link);
  };

  const handleChangeSearch = event => {
    setLoadingResult(true);
    setSearchKey(event.target.value);
  };

  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };

  const onChangePage = value => {
    setCurrentPage(value);
    setCurrentPageApi(value);
    setPage(value);
  };

  const onChangeTotal = value => {
    setTotalSizeApi(value);
  };

  const onDelAct = async value => {
    setLoadingDelete(true);
    try {
      let theToken = localStorage.getItem("token");
      let baseOfUrl = hardBaseUrl;
      let urlUse = baseOfUrl + "/bank/" + value;
      let optsDel = {
        headers: {
          Authorization: "Bearer " + theToken,
        },
      };
      const responseDel = await axios.delete(urlUse, optsDel);

      if (
        responseDel.data.message === "success" &&
        responseDel.data.meta.http_status === 200
      ) {
        setLoadingDelete(false);

        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Data berhasil dihapus",
        }).then(result => {
          if (result.isConfirmed === true) {
            window.location.reload();
          }
        });
      }
    } catch (error) {}
  };

  const onEditAct = async value => {
    setLoadingDelete(true);

    const formData = new FormData();
    formData.append("name", value.name);
    formData.append("code", value.code);

    if (value.profile_image !== "") {
      formData.append("image_logo", value.profile_image);
    }

    let baseOfUrl = hardBaseUrl;
    let urlUse = baseOfUrl + "/bank/" + value.bank_id;
    let hardToken = localStorage.getItem("token");
    let resultAdd = await axios.put(urlUse, formData, {
      headers: {
        Authorization: "Bearer " + hardToken,
      },
    });
    if (
      resultAdd.data.message === "success" &&
      resultAdd.data.meta.http_status === 200
    ) {
      setLoadingDelete(false);

      Swal.fire({
        title: "Success",
        text: "Data berhasil diubah",
        icon: "success",
        confirmButtonText: "Ok",
      }).then(result => {
        if (result.isConfirmed) {
          setReloadList(true);
        }
      });
    }
  };

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const pageSizePagination = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const handleTambah = () => {
    setOpenModalTambah(true);
  };

  const handleCloseTambah = () => {
    setOpenModalTambah(false);
  };

  const handleChangePhotoButton = e => {
    e.preventDefault();
    inputFile.current.click();
  };

  const onChangeFile = e => {
    const data = e.target.files[0];
    const object = URL.createObjectURL(data);
    setNewDataLogoBank(data);
    setNewLogoBank(object);
  };

  const simpanNewBank = async () => {
    setOpenModalTambah(false);
    if (newDataLogoBank === "") {
      Swal.fire({
        icon: "error",
        title: "Gagal",
        text: "Logo Tidak Boleh Kosong.",
      }).then(result => {
        if (result.isConfirmed === true) {
          setOpenModalTambah(true);
        }
      });
    }
    if (newKodeBank === "") {
      Swal.fire({
        icon: "error",
        title: "Gagal",
        text: "Kode Tidak Boleh Kosong.",
      }).then(result => {
        if (result.isConfirmed === true) {
          setOpenModalTambah(true);
        }
      });
    }
    if (newNamaBank === "") {
      Swal.fire({
        icon: "error",
        title: "Gagal",
        text: "Nama Tidak Boleh Kosong.",
      }).then(result => {
        if (result.isConfirmed === true) {
          setOpenModalTambah(true);
        }
      });
    }

    if (newDataLogoBank !== "" && newKodeBank !== "" && newNamaBank !== "") {
      const formData = new FormData();
      formData.append("name", newNamaBank);
      formData.append("code", newKodeBank);
      formData.append("image_logo", newDataLogoBank);
      let baseOfUrl = hardBaseUrl;
      let urlUse = baseOfUrl + "/bank";
      let theToken = localStorage.getItem("token");
      let resultAdd = await axios.post(urlUse, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + theToken,
        },
      });
      if (
        resultAdd.data.message === "success" &&
        resultAdd.data.meta.http_status === 200
      ) {
        Swal.fire({
          title: "Success",
          text: "Tambah Bank Berhasil.",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(result => {
          if (result.isConfirmed) {
            setReloadList(true);
          }
        });
      }
    }
  };

  return (
    <Page className={classes.root} title="Bank">
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
          {message}
        </Alert>
      </Snackbar>
      {loadingDelete ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Configuration" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop items={topMenuButtonMPIS(userRole)} />
          <Divider className={classes.divider} />
          <Grid container>
            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
              <div
                style={{
                  display: "flex",
                }}
              >
                <Button
                  onClick={() => {
                    handleTambah();
                  }}
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    width: "auto",
                    height: "44px",
                  }}
                  variant="contained"
                  startIcon={<AddIcon />}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      style={{
                        fontSize: "16px",
                        textTransform: "none",
                      }}
                    >
                      Tambah Bank
                    </Typography>
                  </ThemeProvider>
                </Button>
                <Dialog
                  open={openModalTambah}
                  onClose={handleCloseTambah}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    style={{
                      padding: "0px 0px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <ThemeProvider theme={fontInter}>
                        <Typography
                          style={{
                            fontWeight: 700,
                            fontSize: "16px",
                            lineHeight: "24px",
                            paddingBottom: "16px",
                            paddingTop: "16px",
                            paddingLeft: "24px",
                          }}
                        >
                          Tambah Bank
                        </Typography>
                      </ThemeProvider>
                      <IconButton
                        aria-label="close"
                        onClick={() => setOpenModalTambah(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </DialogTitle>
                  <Divider />
                  <DialogContent>
                    <div
                      style={{
                        marginTop: "16px",
                      }}
                    >
                      <ThemeProvider theme={fontInter}>
                        <Typography
                          style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "20px",
                          }}
                        >
                          Nama Bank
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "6px",
                      }}
                    >
                      <OutlinedInput
                        fullWidth={true}
                        value={newNamaBank}
                        onChange={e => setNewNamaBank(e.target.value)}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "16px",
                      }}
                    >
                      <ThemeProvider theme={fontInter}>
                        <Typography
                          style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "20px",
                          }}
                        >
                          Kode Bank
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "6px",
                      }}
                    >
                      <OutlinedInput
                        fullWidth={true}
                        value={newKodeBank}
                        onChange={e => setNewKodeBank(e.target.value)}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "16px",
                      }}
                    >
                      <ThemeProvider theme={fontInter}>
                        <Typography
                          style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "20px",
                          }}
                        >
                          Logo Bank
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "6px",
                        border: "1px solid #D1D5DC",
                        width: "402px",
                        height: "56px",
                        borderRadius: "6px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        display: "flex",
                      }}
                    >
                      <input
                        onChange={onChangeFile}
                        ref={inputFile}
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        style={{
                          display: "none",
                        }}
                      />
                      <Button
                        variant="contained"
                        style={{
                          marginLeft: "14px",
                          border: "1px solid #D1D5DC",
                          backgroundColor: "white",
                        }}
                        onClick={handleChangePhotoButton}
                      >
                        <ThemeProvider theme={fontInter}>
                          <Typography
                            style={{
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "20px",
                              textTransform: "none",
                            }}
                          >
                            Unggah
                          </Typography>
                        </ThemeProvider>
                      </Button>
                      {newLogoBank !== "" ? (
                        <img src={newLogoBank} style={img} />
                      ) : (
                        <ThemeProvider theme={fontInter}>
                          <Typography
                            style={{
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "20px",
                              textTransform: "none",
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            Belum ada
                          </Typography>
                        </ThemeProvider>
                      )}
                    </div>
                  </DialogContent>
                  <Divider />
                  <DialogActions
                    style={{
                      paddingLeft: "24px",
                      paddingRight: "24px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setOpenModalTambah(false);
                      }}
                      style={{
                        height: "40px",
                        width: "90px",
                        borderRadius: "6px",
                        border: "1px solid #D1D5DC",
                        color: "#111827",
                        backgroundColor: "white",
                        textTransform: "none",
                      }}
                    >
                      <ThemeProvider theme={fontInter}>
                        <Typography
                          style={{
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "24px",
                          }}
                        >
                          Batal
                        </Typography>
                      </ThemeProvider>
                    </Button>
                    <Button
                      onClick={() => {
                        simpanNewBank();
                      }}
                      autoFocus
                      style={{
                        height: "40px",
                        width: "90px",
                        borderRadius: "6px",
                        border: "0px solid #D1D5DC",
                        color: "white",
                        backgroundColor: "#111827",
                        textTransform: "none",
                      }}
                    >
                      <ThemeProvider theme={fontInter}>
                        <Typography
                          style={{
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "24px",
                          }}
                        >
                          Simpan
                        </Typography>
                      </ThemeProvider>
                    </Button>
                  </DialogActions>
                </Dialog>

                <FormControl
                  className={classes.margin}
                  variant="outlined"
                  style={{
                    marginLeft: "8px",
                  }}
                >
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    className={classes.inputFields}
                    value={searchKey}
                    onChange={event => handleChangeSearch(event)}
                    placeholder="Pencarian"
                    startAdornment={
                      <InputAdornment position="start">
                        <img
                          alt="Logo"
                          src={
                            require("assets/image-public/images/search.svg")
                              .default
                          }
                        />
                      </InputAdornment>
                    }
                    fullWidth={false}
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          {customers && (
            <Results
              className={classes.results}
              items={customers}
              isLoading={loadingResult}
              handleChangePage={onChangePage}
              handleChangeTotalPage={onChangeTotal}
              currentPageNumber={currentPage}
              handleDelRec={onDelAct}
              handleEditRec={onEditAct}
              pageSizePagination={pageSizePagination()}
              totalData={pagesCount}
              page={page}
            />
          )}
        </Container>
      )}
    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Configuration",
    link: "/admin/parameter/bank-admin",
  },
  {
    label: "Bank",
    active: true,
  },
];

export default Bank;
