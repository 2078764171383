
import React, {
  useState,
  useEffect,
} from "react";
import {
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  Container,
  Divider,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  Breadcrumbs,
} from "@material-ui/core";
import { Page } from "components";
import axios from "axios";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { hardBaseUrl } from "../../../services/urlConstant";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import Swal from "sweetalert2";
import imageUpload from "../../../assets/img/imageUpload.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setTentangKamiImage1,
  setTentangKamiImage2,
  setVisiKamiImage1,
  setVisiKamiImage2,
  setPublisherImage1,
  setPublisherImage2,
  setPublisherImage3,
  setPublisherImage4,
  setPublisherImage5,
  setPublisherImage6,
  getTentangKamiText,
  getVisiKamiText,
  getDspName,
  getDspLogo,
} from "../../../constants/landingPage/manajemenWeb";
import CircularProgress from "@material-ui/core/CircularProgress";

const theme = createTheme({
  typography: {
    fontFamily: [
      // "-apple-system",
      // "BlinkMacSystemFont",
      // '"Segoe UI"',
      // "Roboto",
      // '"Helvetica Neue"',
      // "Arial",
      // "sans-serif",
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
      "Inter",
    ].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  btnSubmit: {
    backgroundColor: "black",
    marginLeft: "10px",
    color: "white",
    padding: "10px 28px",
    width: "106px",
    borderRadius: "6px",
    height: "40px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnPreview: {
    backgroundColor: "black",
    // marginRight: "10px",
    color: "white",
    padding: "10px 28px",
    width: "106px",
    borderRadius: "6px",
    height: "40px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  error: {
    color: "red",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
}));

const ManajemenWeb = props => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const tentangKamiImage1 = useSelector(state => {
    return state.manajemenWeb.tentangKamiImage1;
  });

  const tentangKamiImage2 = useSelector(state => {
    return state.manajemenWeb.tentangKamiImage2;
  });

  const visiKamiImage1 = useSelector(state => {
    return state.manajemenWeb.visiKamiImage1;
  });

  const visiKamiImage2 = useSelector(state => {
    return state.manajemenWeb.visiKamiImage2;
  });

  const fotoPublisherImage1 = useSelector(state => {
    return state.manajemenWeb.publisherImage1;
  });

  const fotoPublisherImage2 = useSelector(state => {
    return state.manajemenWeb.publisherImage2;
  });

  const fotoPublisherImage3 = useSelector(state => {
    return state.manajemenWeb.publisherImage3;
  });

  const fotoPublisherImage4 = useSelector(state => {
    return state.manajemenWeb.publisherImage4;
  });

  const fotoPublisherImage5 = useSelector(state => {
    return state.manajemenWeb.publisherImage5;
  });

  const fotoPublisherImage6 = useSelector(state => {
    return state.manajemenWeb.publisherImage6;
  });

  const [textEditor, setTextEditor] = useState(EditorState.createEmpty());

  const [textEditor2, setTextEditor2] = useState(EditorState.createEmpty());
  const [getColor, setGetColor] = useState("");
  // const [imagesArray, setImagesArray] = useState("");

  // Lokal
  const [tentangKamiImage1Local, setTentangKamiImage1Local] = useState(null);
  const [tentangKamiImage2Local, setTentangKamiImage2Local] = useState(null);
  const [visiKamiImage1Local, setVisiKamiImage1Local] = useState(null);
  const [visiKamiImage2Local, setVisiKamiImage2Local] = useState(null);
  const [publisherImage1Local, setPublisherImage1Local] = useState(null);
  const [publisherImage2Local, setPublisherImage2Local] = useState(null);
  const [publisherImage3Local, setPublisherImage3Local] = useState(null);
  const [publisherImage4Local, setPublisherImage4Local] = useState(null);
  const [publisherImage5Local, setPublisherImage5Local] = useState(null);
  const [publisherImage6Local, setPublisherImage6Local] = useState(null);
  // const [localTextAboutUs, setLocalTextAboutUs] = useState(null);
  // const [localTextVision, setLocalTextVision] = useState(null);

  const [tentangKamiImage1LocalId, setTentangKamiImage1LocalId] = useState(
    null
  );
  const [tentangKamiImage2LocalId, setTentangKamiImage2LocalId] = useState(
    null
  );
  const [visiKamiImage1LocalId, setVisiKamiImage1LocalId] = useState(null);
  const [visiKamiImage2LocalId, setVisiKamiImage2LocalId] = useState(null);
  const [publisherImage1LocalId, setPublisherImage1LocalId] = useState(null);
  const [publisherImage2LocalId, setPublisherImage2LocalId] = useState(null);
  const [publisherImage3LocalId, setPublisherImage3LocalId] = useState(null);
  const [publisherImage4LocalId, setPublisherImage4LocalId] = useState(null);
  const [publisherImage5LocalId, setPublisherImage5LocalId] = useState(null);
  const [publisherImage6LocalId, setPublisherImage6LocalId] = useState(null);
  // const [localTextAboutUsId, setLocalTextAboutUsId] = useState(null);
  // const [localTextVisionId, setLocalTextVisionId] = useState(null);
  const [dspName, setDspName] = useState("");
  const [dspLogo, setDspLogo] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [arrDelImage, setArrDelImage] = useState([]);

  const [error] = useState({
    tentangKamiImage: "",
    visiKamiImage: "",
    publisherImage: "",
    textEditor1: "",
    textEditor2: "",
  });

  const handleChangeImage = e => {
    if (
      e.target.files.length &&
      e.target.name === "tentangKamiImage1" &&
      e.target.files[0].size <= 2000000
    ) {
      dispatch(setTentangKamiImage1(e.target.files[0]));
      if (tentangKamiImage1LocalId !== null) {
        let newDelImg = arrDelImage;
        newDelImg.push(tentangKamiImage1LocalId);
        setArrDelImage(newDelImg);
      }
    } else if (
      e.target.files.length &&
      e.target.name === "tentangKamiImage2" &&
      e.target.files[0].size <= 2000000
    ) {
      dispatch(setTentangKamiImage2(e.target.files[0]));
      if (tentangKamiImage2LocalId !== null) {
        let newDelImg = arrDelImage;
        newDelImg.push(tentangKamiImage2LocalId);
        setArrDelImage(newDelImg);
      }
    } else if (
      e.target.files.length &&
      e.target.name === "visiKamiImage1" &&
      e.target.files[0].size <= 2000000
    ) {
      dispatch(setVisiKamiImage1(e.target.files[0]));
      if (visiKamiImage1LocalId !== null) {
        let newDelImg = arrDelImage;
        newDelImg.push(visiKamiImage1LocalId);
        setArrDelImage(newDelImg);
      }
    } else if (
      e.target.files.length &&
      e.target.name === "visiKamiImage2" &&
      e.target.files[0].size <= 2000000
    ) {
      dispatch(setVisiKamiImage2(e.target.files[0]));
      if (visiKamiImage2LocalId !== null) {
        let newDelImg = arrDelImage;
        newDelImg.push(visiKamiImage2LocalId);
        setArrDelImage(newDelImg);
      }
    } else if (
      e.target.files.length &&
      e.target.name === "fotoPublisherImage1" &&
      e.target.files[0].size <= 2000000
    ) {
      dispatch(setPublisherImage1(e.target.files[0]));
      if (publisherImage1LocalId !== null) {
        let newDelImg = arrDelImage;
        newDelImg.push(publisherImage1LocalId);
        setArrDelImage(newDelImg);
      }
    } else if (
      e.target.files.length &&
      e.target.name === "fotoPublisherImage2" &&
      e.target.files[0].size <= 2000000
    ) {
      dispatch(setPublisherImage2(e.target.files[0]));
      if (publisherImage2LocalId !== null) {
        let newDelImg = arrDelImage;
        newDelImg.push(publisherImage2LocalId);
        setArrDelImage(newDelImg);
      }
    } else if (
      e.target.files.length &&
      e.target.name === "fotoPublisherImage3" &&
      e.target.files[0].size <= 2000000
    ) {
      dispatch(setPublisherImage3(e.target.files[0]));
      if (publisherImage3LocalId !== null) {
        let newDelImg = arrDelImage;
        newDelImg.push(publisherImage3LocalId);
        setArrDelImage(newDelImg);
      }
    } else if (
      e.target.files.length &&
      e.target.name === "fotoPublisherImage4" &&
      e.target.files[0].size <= 2000000
    ) {
      dispatch(setPublisherImage4(e.target.files[0]));
      if (publisherImage4LocalId !== null) {
        let newDelImg = arrDelImage;
        newDelImg.push(publisherImage4LocalId);
        setArrDelImage(newDelImg);
      }
    } else if (
      e.target.files.length &&
      e.target.name === "fotoPublisherImage5" &&
      e.target.files[0].size <= 2000000
    ) {
      dispatch(setPublisherImage5(e.target.files[0]));
      if (publisherImage5LocalId !== null) {
        let newDelImg = arrDelImage;
        newDelImg.push(publisherImage5LocalId);
        setArrDelImage(newDelImg);
      }
    } else if (
      e.target.files.length &&
      e.target.name === "fotoPublisherImage6" &&
      e.target.files[0].size <= 2000000
    ) {
      dispatch(setPublisherImage6(e.target.files[0]));
      if (publisherImage6LocalId !== null) {
        let newDelImg = arrDelImage;
        newDelImg.push(publisherImage6LocalId);
        setArrDelImage(newDelImg);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Ukuran file maksimal 2MB",
      });
    }
  };
  const handleSubmit = async () => {
    setLoadingSubmit(true);
    const token = localStorage.getItem("token");
    const rawContentState = convertToRaw(textEditor.getCurrentContent());
    const markup = draftToHtml(rawContentState);

    const rawContentState2 = convertToRaw(textEditor2.getCurrentContent());
    const markup2 = draftToHtml(rawContentState2);
    const data = new FormData();

    const deleteImage = arrDelImage;

    data.append("about_us", markup);
    data.append("our_vision", markup2);

    data.append("about_us_images", tentangKamiImage1.raw);
    data.append("about_us_images", tentangKamiImage2.raw);

    data.append("our_vision_images", visiKamiImage1.raw);
    data.append("our_vision_images", visiKamiImage2.raw);

    data.append("publisher_images", fotoPublisherImage1.raw);
    data.append("publisher_images", fotoPublisherImage2.raw);
    data.append("publisher_images", fotoPublisherImage3.raw);
    data.append("publisher_images", fotoPublisherImage4.raw);
    data.append("publisher_images", fotoPublisherImage5.raw);
    data.append("publisher_images", fotoPublisherImage6.raw);

    data.append("delete_img_id", JSON.stringify(deleteImage));

    const url = `${hardBaseUrl}/publisher/web-management`;
    axios
      .post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setLoadingSubmit(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Data Berhasil Ditambahkan",
        }).then(result => {
          if (result.isConfirmed === true) {
            window.location.reload();
          }
        });
      })
      .catch(() => {
        setLoadingSubmit(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Terjadi Kesalahan",
        });
      });
  };

  const handlePreview = async () => {
    localStorage.setItem("dspName", dspName);
    localStorage.setItem("dspLogo", dspLogo);
    localStorage.setItem("publisherImage1", publisherImage1Local);
    localStorage.setItem("publisherImage2", publisherImage2Local);
    localStorage.setItem("publisherImage3", publisherImage3Local);
    localStorage.setItem("publisherImage4", publisherImage4Local);
    localStorage.setItem("publisherImage5", publisherImage5Local);
    localStorage.setItem("publisherImage6", publisherImage6Local);
    localStorage.setItem("tentangKamiImage1", tentangKamiImage1Local);
    localStorage.setItem("tentangKamiImage2", tentangKamiImage2Local);
    localStorage.setItem("visiKamiImage1", visiKamiImage1Local);
    localStorage.setItem("visiKamiImage2", visiKamiImage2Local);

    props.history.push("/admin/manajemen-web/preview");
  };

  const rawContentState = convertToRaw(textEditor.getCurrentContent());
  const markup = draftToHtml(rawContentState);
  dispatch(getTentangKamiText(markup));

  const rawContentState2 = convertToRaw(textEditor2.getCurrentContent());
  const markup2 = draftToHtml(rawContentState2);
  dispatch(getVisiKamiText(markup2));

  const getDspInfo = () => {
    const token = localStorage.getItem("token");

    const url = `${hardBaseUrl}/me`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        dispatch(getDspName(res.data.data.publisher.name));
        setDspName(res.data.data.publisher.name);
        dispatch(getDspLogo(res.data.data.publisher.profile_image));
        setDspLogo(res.data.data.publisher.profile_image);
      })
      .catch(() => {
        // console.log(err);
      });
  };

  const getMainDsp = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/me`;
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setGetColor(res.data.data.publisher.theme_color);
    } catch (e) {
      new Error(e);
    }
  };
  const getResponse = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/web-management`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async res => {

        const blocksFromHtmla = htmlToDraft(res.data.data.about_us);
        let { contentBlocks, entityMap } = blocksFromHtmla;
        const contentStatea = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorStatea = EditorState.createWithContent(contentStatea);

        const blocksFromHtmlb = htmlToDraft(res.data.data.our_vision);
        let contentBlocksb = blocksFromHtmlb.contentBlocks;
        let entityMapb = blocksFromHtmlb.entityMap;
        const contentStateb = ContentState.createFromBlockArray(
          contentBlocksb,
          entityMapb
        );
        const editorStateb = EditorState.createWithContent(contentStateb);

        // setLocalTextAboutUs(response.data.data.about_us)
        // setLocalTextVision(response.data.data.our_vision)

        setTextEditor(editorStatea);
        setTextEditor2(editorStateb);
        const arrImage = res.data.data.images;
        // console.log("arrImage", arrImage);
        if (arrImage !== null) {
          // console.log("arrImage >>>>>>>>>>>> ");
          const aboutUsImages = await arrImage.filter(
            item => item.type === "about_us"
          );
          setTentangKamiImage1Local(aboutUsImages[0].image_name);
          setTentangKamiImage2Local(aboutUsImages[1].image_name);
          setTentangKamiImage1LocalId(aboutUsImages[0].web_management_image_id);
          setTentangKamiImage2LocalId(aboutUsImages[1].web_management_image_id);

          const ourVisionImages = await arrImage.filter(
            item => item.type === "our_vision"
          );
          setVisiKamiImage1Local(ourVisionImages[0].image_name);
          setVisiKamiImage2Local(ourVisionImages[1].image_name);
          setVisiKamiImage1LocalId(ourVisionImages[0].web_management_image_id);
          setVisiKamiImage2LocalId(ourVisionImages[1].web_management_image_id);

          const publisherImages = await arrImage.filter(
            item => item.type === "publisher"
          );
          setPublisherImage1Local(publisherImages[0].image_name);
          setPublisherImage2Local(publisherImages[1].image_name);
          setPublisherImage3Local(publisherImages[2].image_name);
          setPublisherImage4Local(publisherImages[3].image_name);
          setPublisherImage5Local(publisherImages[4].image_name);
          setPublisherImage6Local(publisherImages[5].image_name);

          setPublisherImage1LocalId(publisherImages[0].web_management_image_id);
          setPublisherImage2LocalId(publisherImages[1].web_management_image_id);
          setPublisherImage3LocalId(publisherImages[2].web_management_image_id);
          setPublisherImage4LocalId(publisherImages[3].web_management_image_id);
          setPublisherImage5LocalId(publisherImages[4].web_management_image_id);
          setPublisherImage6LocalId(publisherImages[5].web_management_image_id);

        }
      })
      .catch((e) => {
        new Error(e);
      });
  };

  useEffect(() => {
    getResponse();
    getDspInfo();
    getMainDsp();
  }, [getDspInfo]);

  return (
    <Page className={classes.root} title="Web Management">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                // component="span"
                // variant="h6"
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                {"Web Management"}
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbsActive}>
                {"Web Management"}
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container>
          <div>
            {/* <img
            src={localStorage.getItem("tentangKamiImage1")}
            /> */}
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                About Us
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Information about us
              </p>
              {/* <div>{ReactHtmlParser(markup)}</div> */}
            </ThemeProvider>
          </div>
        </Grid>

        <Grid container>
          <Grid item xs={9} md={9} sm={9} lg={9} xl={9}>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <ThemeProvider theme={theme}>
                  <Typography>
                    <label htmlFor="logo" className={classes.label}>
                      Photo *
                    </label>
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "5px",
                    }}
                  >
                    <div>
                      <label htmlFor="upload-button1">
                        {tentangKamiImage1.preview ? (
                          <img
                            src={tentangKamiImage1.preview}
                            alt="dummy"
                            style={{
                              width: "162px",
                              height: "161.75px",
                            }}
                          />
                        ) : (
                          <>
                            {tentangKamiImage1Local !== null ? (
                              <img
                                src={tentangKamiImage1Local}
                                alt="dummysas"
                                style={{
                                  width: "162px",
                                  height: "161.75px",
                                }}
                              />
                            ) : (
                              <img
                                src={imageUpload}
                                alt="dummya"
                                style={{
                                  width: "162px",
                                  height: "161.75px",
                                }}
                              />
                            )}
                          </>
                        )}
                      </label>
                      <input
                        type="file"
                        name="tentangKamiImage1"
                        id="upload-button1"
                        style={{ display: "none" }}
                        accept="image/png, image/gif, image/jpeg"
                        onChange={e => handleChangeImage(e)}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: "20px",
                      }}
                    >
                      <label htmlFor="upload-button2">
                        {tentangKamiImage2.preview ? (
                          <img
                            src={tentangKamiImage2.preview}
                            alt="dummy"
                            style={{
                              width: "162px",
                              height: "161.75px",
                            }}
                          />
                        ) : (
                          <>
                            {tentangKamiImage2Local !== null ? (
                              <img
                                src={tentangKamiImage2Local}
                                alt="dummysas"
                                style={{
                                  width: "162px",
                                  height: "161.75px",
                                }}
                              />
                            ) : (
                              <img
                                src={imageUpload}
                                alt="dummya"
                                style={{
                                  width: "162px",
                                  height: "161.75px",
                                }}
                              />
                            )}
                          </>
                        )}
                      </label>
                      <input
                        type="file"
                        id="upload-button2"
                        name="tentangKamiImage2"
                        style={{ display: "none" }}
                        onChange={e => handleChangeImage(e)}
                        accept="image/png, image/gif, image/jpeg"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Typography>
                      <label htmlFor="logo" className={classes.label}>
                        Contents *
                      </label>
                    </Typography>

                    <div
                      style={{
                        marginTop: "5px",
                      }}
                    >
                      <Editor
                        editorState={textEditor}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class input_description"
                        editorStyle={{
                          height: "30vH",
                          backgroundColor: "white",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                        // editorClassName={{
                        //   height: '60vH'
                        // }}
                        toolbarClassName="toolbar-class"
                        wrapperStyle={{
                          border: "1px solid #ccc",
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                        toolbar={{
                          options: [
                            "inline",
                            "blockType",
                            "fontSize",
                            "list",
                            "textAlign",
                            "colorPicker",
                            "link",
                            "remove",
                            "history",
                          ],
                        }}
                        onEditorStateChange={setTextEditor}
                      />
                    </div>
                  </div>
                </ThemeProvider>
              </CardContent>
            </Card>
            {tentangKamiImage1.raw === "" ||
              tentangKamiImage2.raw === "" ||
              textEditor.getCurrentContent().hasText() === false ? (
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginTop: "5px",
                  }}
                >
                  {error.tentangKamiImage}
                </Typography>
              </ThemeProvider>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={3} md={3} sm={3} lg={3} xl={3}></Grid>
        </Grid>

        <div
          style={{
            marginTop: "20px",
          }}
        >
          <Grid container>
            <div>
              <ThemeProvider theme={theme}>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Our Vision
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  This is the vision of your company
                </p>
              </ThemeProvider>
            </div>
          </Grid>

          <Grid container>
            <Grid item xs={9} md={9} sm={9} lg={9} xl={9}>
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <ThemeProvider theme={theme}>
                    <Typography>
                      <label htmlFor="logo" className={classes.label}>
                        Photo *
                      </label>
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "5px",
                      }}
                    >
                      <div>
                        <label htmlFor="upload-button3">
                          {visiKamiImage1.preview ? (
                            <img
                              src={visiKamiImage1.preview}
                              alt="dummy"
                              style={{
                                width: "162px",
                                height: "161.75px",
                              }}
                            />
                          ) : (
                            <>
                              {visiKamiImage1Local !== null ? (
                                <img
                                  src={visiKamiImage1Local}
                                  alt="dummysas"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={imageUpload}
                                  alt="dummya"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          id="upload-button3"
                          name="visiKamiImage1"
                          style={{ display: "none" }}
                          onChange={e => handleChangeImage(e)}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                        }}
                      >
                        <label htmlFor="upload-button4">
                          {visiKamiImage2.preview ? (
                            <img
                              src={visiKamiImage2.preview}
                              alt="dummy"
                              style={{
                                width: "162px",
                                height: "161.75px",
                              }}
                            />
                          ) : (
                            <>
                              {visiKamiImage2Local !== null ? (
                                <img
                                  src={visiKamiImage2Local}
                                  alt="dummysas"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={imageUpload}
                                  alt="dummya"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          id="upload-button4"
                          name="visiKamiImage2"
                          style={{ display: "none" }}
                          onChange={e => handleChangeImage(e)}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <Typography>
                        <label htmlFor="logo" className={classes.label}>
                          Contents *
                        </label>
                      </Typography>

                      <div
                        style={{
                          marginTop: "5px",
                        }}
                      >
                        <Editor
                          editorState={textEditor2}
                          // wrapperClassName="wrapper-class"
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class input_description"
                          // editorClassName={{
                          //   backgroundColor: 'red'
                          // }}
                          editorStyle={{
                            height: "30vH",
                            backgroundColor: "white",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                          // editorClassName={{
                          //   height: '60vH'
                          // }}
                          toolbarClassName="toolbar-class"
                          wrapperStyle={{
                            border: "1px solid #ccc",
                            paddingLeft: 0,
                            paddingRight: 0,
                          }}
                          toolbar={{
                            options: [
                              "inline",
                              "blockType",
                              "fontSize",
                              "list",
                              "textAlign",
                              "colorPicker",
                              "link",
                              "remove",
                              "history",
                            ],
                          }}
                          onEditorStateChange={setTextEditor2}
                        />
                      </div>
                    </div>
                  </ThemeProvider>
                </CardContent>
              </Card>
              {visiKamiImage1.raw === "" ||
                visiKamiImage2.raw === "" ||
                textEditor2.getCurrentContent().hasText() === false ? (
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "5px",
                    }}
                  >
                    {error.visiKamiImage}
                  </Typography>
                </ThemeProvider>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={3} md={3} sm={3} lg={3} xl={3}></Grid>
          </Grid>
        </div>

        <div
          style={{
            marginTop: "20px",
          }}
        >
          <Grid container>
            <div>
              <ThemeProvider theme={theme}>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Publisher Photo
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  This photo will be displayed on the landing page
                </p>
              </ThemeProvider>
            </div>
          </Grid>

          <Grid container>
            <Grid item xs={9} md={9} sm={9} lg={9} xl={9}>
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <ThemeProvider theme={theme}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div>
                        <label htmlFor="upload-button5">
                          {fotoPublisherImage1.preview ? (
                            <img
                              src={fotoPublisherImage1.preview}
                              alt="dummy"
                              style={{
                                width: "162px",
                                height: "161.75px",
                              }}
                            />
                          ) : (
                            <>
                              {publisherImage1Local !== null ? (
                                <img
                                  src={publisherImage1Local}
                                  alt="dummysas"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={imageUpload}
                                  alt="dummya"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          id="upload-button5"
                          name="fotoPublisherImage1"
                          style={{ display: "none" }}
                          onChange={e => handleChangeImage(e)}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                        }}
                      >
                        <label htmlFor="upload-button6">
                          {fotoPublisherImage2.preview ? (
                            <img
                              src={fotoPublisherImage2.preview}
                              alt="dummy"
                              style={{
                                width: "162px",
                                height: "161.75px",
                              }}
                            />
                          ) : (
                            <>
                              {publisherImage2Local !== null ? (
                                <img
                                  src={publisherImage2Local}
                                  alt="dummysas"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={imageUpload}
                                  alt="dummya"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          id="upload-button6"
                          name="fotoPublisherImage2"
                          style={{ display: "none" }}
                          onChange={e => handleChangeImage(e)}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>

                      <div
                        style={{
                          marginLeft: "20px",
                        }}
                      >
                        <label htmlFor="upload-button7">
                          {fotoPublisherImage3.preview ? (
                            <img
                              src={fotoPublisherImage3.preview}
                              alt="dummy"
                              style={{
                                width: "162px",
                                height: "161.75px",
                              }}
                            />
                          ) : (
                            <>
                              {publisherImage3Local !== null ? (
                                <img
                                  src={publisherImage3Local}
                                  alt="dummysas"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={imageUpload}
                                  alt="dummya"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          name="fotoPublisherImage3"
                          id="upload-button7"
                          style={{ display: "none" }}
                          onChange={e => handleChangeImage(e)}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>

                      <div
                        style={{
                          marginLeft: "20px",
                        }}
                      >
                        <label htmlFor="upload-button8">
                          {fotoPublisherImage4.preview ? (
                            <img
                              src={fotoPublisherImage4.preview}
                              alt="dummy"
                              style={{
                                width: "162px",
                                height: "161.75px",
                              }}
                            />
                          ) : (
                            <>
                              {publisherImage4Local !== null ? (
                                <img
                                  src={publisherImage4Local}
                                  alt="dummysas"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={imageUpload}
                                  alt="dummya"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          id="upload-button8"
                          name="fotoPublisherImage4"
                          style={{ display: "none" }}
                          onChange={e => handleChangeImage(e)}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <div>
                        <label htmlFor="upload-button9">
                          {fotoPublisherImage5.preview ? (
                            <img
                              src={fotoPublisherImage5.preview}
                              alt="dummy"
                              style={{
                                width: "162px",
                                height: "161.75px",
                              }}
                            />
                          ) : (
                            <>
                              {publisherImage5Local !== null ? (
                                <img
                                  src={publisherImage5Local}
                                  alt="dummysas"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={imageUpload}
                                  alt="dummya"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          id="upload-button9"
                          name="fotoPublisherImage5"
                          style={{ display: "none" }}
                          onChange={e => handleChangeImage(e)}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                        }}
                      >
                        <label htmlFor="upload-button10">
                          {fotoPublisherImage6.preview ? (
                            <img
                              src={fotoPublisherImage6.preview}
                              alt="dummy"
                              style={{
                                width: "162px",
                                height: "161.75px",
                              }}
                            />
                          ) : (
                            <>
                              {publisherImage6Local !== null ? (
                                <img
                                  src={publisherImage6Local}
                                  alt="dummysas"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={imageUpload}
                                  alt="dummya"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          id="upload-button10"
                          name="fotoPublisherImage6"
                          style={{ display: "none" }}
                          onChange={e => handleChangeImage(e)}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>
                    </div>
                  </ThemeProvider>
                </CardContent>
              </Card>
              {fotoPublisherImage1.raw === "" ||
                fotoPublisherImage2.raw === "" ||
                fotoPublisherImage3.raw === "" ||
                fotoPublisherImage4.raw === "" ||
                fotoPublisherImage5.raw === "" ||
                fotoPublisherImage6.raw === "" ? (
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "5px",
                    }}
                  >
                    {error.publisherImage}
                  </Typography>
                </ThemeProvider>
              ) : (
                <></>
              )}
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <Button
                  className={classes.btnPreview}
                  onClick={handlePreview}
                  style={{
                    backgroundColor: getColor,
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      style={{
                        textTransform: "none",
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                      }}
                    >
                      Preview
                    </Typography>
                  </ThemeProvider>
                </Button>
                <Button
                  className={classes.btnSubmit}
                  onClick={handleSubmit}
                  style={{
                    backgroundColor: getColor,
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      style={{
                        textTransform: "none",
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                      }}
                    >
                      {/* Simpan */}
                      {loadingSubmit ? (
                        <CircularProgress color="light" size={20} />
                      ) : (
                        "Save"
                      )}
                    </Typography>
                  </ThemeProvider>
                </Button>
              </div>
            </Grid>
            <Grid item xs={3} md={3} sm={3} lg={3} xl={3}></Grid>
          </Grid>
        </div>
      </Container>
    </Page>
  );
};

export default ManajemenWeb;
