import React from "react";
import iconInitCatalogue from "../../../assets/icons/icon_init_catalogue_grey.svg";
import iconInitCatalogueSelected from "../../../assets/icons/icon_init_catalogue_white.svg";
import navConfigAdmin from "./navConfigAdmin";
import navConfigPublisher from "./navConfigPublisher";
import { TrendingUpTwoTone, CheckCircleOutline } from "@material-ui/icons";
import icon_dashboard_unselected from "../../../assets/image-public/images/icon_images/icon_dashboard_unselected.svg";
import icon_dashboard from "../../../assets/image-public/images/icon_images/icon_dashboard.svg";
import icon_review_unselected from "../../../assets/image-public/images/icon_images/icon_review_unselected.svg";
import icon_master_unselected from "../../../assets/image-public/images/icon_images/icon_master_unselected.svg";
import icon_kontrak_unselected from "../../../assets/image-public/images/icon_images/icon_kontrak_unselected.svg";
import icon_berita_unselected from "../../../assets/image-public/images/icon_images/icon_berita_unselected.svg";
import reviewSelected from "../../../assets/image-public/images/icon_images/reviewSelected.svg";
import Setting from "../../../assets/image-public/images/admin-master-logo-svg/Setting.svg";
import ProgramingData from "../../../assets/image-public/images/admin-master-logo-svg/ProgramingData.svg";
import admin_billing_unselected from "../../../assets/image-public/images/icon_images/admin_billing_unselected.svg";
import admin_billing_selected from "../../../assets/image-public/images/icon_images/admin_billing_selected.svg";
import song_claim_unselected from "../../../assets/image-public/images/song_claim_unselected.svg";
import song_claim_selected from "../../../assets/image-public/images/song_claim_selected.svg";
import BookOpen from "../../../assets/image-public/images/admin-master-logo-svg/BookOpen.png";
import bxTableUnselect from "../../../assets/image-public/images/admin-master-logo-svg/bx-table-unselect.svg";
import bxTableselect from "../../../assets/image-public/images/admin-master-logo-svg/bx-table.svg";

let navItemUsed = [];
let roleUsed = "all";
switch (roleUsed) {
  case "admin":
    navItemUsed = navConfigAdmin;
    break;
  case "publisher":
    navItemUsed = navConfigPublisher;
    break;
  default:
    navItemUsed = [
      {
        subheader: "",
        items: [
          {
            title: "Dashboard",
            href: "/admin/dashboard",
            show: true,
            icon: () => (
              <img
                style={styles.distanceBetweenIconAndText}
                alt={"dashboard"}
                src={icon_dashboard_unselected}
              />
            ),
            selectedicon: () => (
              <img
                style={styles.distanceBetweenIconAndText}
                alt={"dashboard"}
                src={icon_dashboard}
              />
            ),
          },
          {
            title: "Song Usage",
            href: "/admin/review-lagu",
            show: true,
            icon: () => (
              <img
                style={styles.distanceBetweenIconAndText}
                alt="review"
                src={icon_review_unselected}
              />
            ),
            selectedicon: () => (
              <img
                style={styles.distanceBetweenIconAndText}
                alt="review"
                src={reviewSelected}
              />
            ),
          },
          {
            title: "Configuration",
            href: "/admin/konfigurasi/sistem",
            show: true,
            key: "Konfigurasi",
            icon: () => (
              <img
                style={styles.distanceBetweenIconAndText}
                alt="key-nav-config"
                src={icon_kontrak_unselected}
              />
            ),
            selectedicon: () => (
              <img
                style={styles.distanceBetweenIconAndText}
                alt="key-nav-config-selected-icon"
                src={Setting}
              />
            ),
          },
          {
            title: "Parameter",
            href: "/admin/parameter/publisher",
            show: true,
            key: "Master",
            icon: () => (
              <img
                style={styles.distanceBetweenIconAndText}
                alt="Conf"
                src={icon_master_unselected}
              />
            ),
            selectedicon: () => (
              <img
                style={styles.distanceBetweenIconAndText}
                alt="Select file"
                src={ProgramingData}
              />
            ),
          },
          {
            title: "Billing",
            href: "/admin/billing/publisher",
            show: true,
            key: "Billing",
            icon: () => (
              <img
                style={styles.distanceBetweenIconAndText}
                alt="Conf"
                src={admin_billing_unselected}
              />
            ),
            selectedicon: () => (
              <img
                style={styles.distanceBetweenIconAndText}
                alt="Select file"
                src={admin_billing_selected}
              />
            ),
          },
          {
            title: "Song Claim",
            href: "/admin/publisher/double-claim",
            show: true,
            key: "Song Claim",
            icon: () => (
              <img
                style={styles.distanceBetweenIconAndText}
                alt="Conf"
                src={song_claim_unselected}
              />
            ),
            selectedicon: () => (
              <img
                style={styles.distanceBetweenIconAndText}
                alt="Select file"
                src={song_claim_selected}
              />
            ),
          },
          {
            title: "News & Ads",
            href: null,
            show: true,
            key: "Berita & Iklan",
            icon: () => (
              <img
                style={styles.distanceBetweenIconAndText}
                alt="icon-berita-iklan"
                src={icon_berita_unselected}
              />
            ),
            selectedicon: () => (
              <img
                style={styles.distanceBetweenIconAndText}
                alt="selected-icon-berita-iklan"
                src={BookOpen}
              />
            ),
            items: [
              {
                title: "Finalization of News & Ads",
                href: "/admin/finalisasi-berita",
                key: "Finalisasi Berita Iklan",
                show: true,
              },
            ],
          },
          {
            title: "Landing Page",
            href: "/admin/landing-page",
            show: true,
            key: "Landing Page",
            icon: () => (
              <img
                style={styles.distanceBetweenIconAndText}
                alt="icon-berita-iklan"
                src={bxTableUnselect}
              />
            ),
            selectedicon: () => (
              <img
                style={styles.distanceBetweenIconAndText}
                alt="selected-icon-berita-iklan"
                src={bxTableselect}
              />
            ),
          },
          {
            title: "Init Catalogue",
            href: "/admin/init-catalogue",
            show: true,
            key: "Init Catalogue",
            icon: () => (
              <img
                style={styles.distanceBetweenIconAndText}
                alt="icon-berita-iklan"
                src={iconInitCatalogue}
              />
            ),
            selectedicon: () => (
              <img
                style={styles.distanceBetweenIconAndText}
                alt="selected-icon-berita-iklan"
                src={iconInitCatalogueSelected}
              />
            ),
          },
          {
            title: "DSP Usage Process",
            href: "/admin/usage-process",
            show: true,
            key: "Usage Process",
            icon: () => (
              <TrendingUpTwoTone style={styles.distanceBetweenIconAndText} />
            ),
            selectedicon: () => (
              <TrendingUpTwoTone style={styles.distanceBetweenIconAndText} />
            ),
          },
          {
            title: "Submit to DSP",
            href: "/admin/submitDSP",
            show: true,
            key: "Submit DSP",
            icon: () => (
              <CheckCircleOutline style={styles.distanceBetweenIconAndText} />
            ),
            selectedicon: () => (
              <CheckCircleOutline style={styles.distanceBetweenIconAndText} />
            ),
          },
        ],
      },
    ];
}
const items = navItemUsed;

const styles = {
  distanceBetweenIconAndText: {
    width: 20,
    marginRight: 10,
  },
};
export default items;
