import {
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTable } from "inno-ui";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import updateIcon from "../../../../assets/img/pencilVector.svg";
import PencilVectorGrey from "../../../../assets/img/pencilVectorGrey.svg";
import TrashVectorGrey from "../../../../assets/img/trashVectorGrey.svg";
import HeaderPage from "../../../../layouts/Header/HeaderPage";
import { Page, TableLoading, ButtonGroupTop, CustomModal } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { formatFileSize } from "utils";
import { topMenuParameter } from "constants";

const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  inner: {
    minWidth: 700,
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    boxShadow: "none",
  },
  button: {
    marginBottom: "20px",

    backgroundColor: "black",
    color: "white",
    width: "auto",
    height: "35px",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
  },
  colorBox: {
    width: "30px",
    height: "30px",
  },
  tableArea: {
    marginTop: "20px",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  inputFields: {
    "& .MuiOutlinedInput-input": {
      padding: "0px",
      height: "44px",
      width: "268px",
    },
  },
  ib: {
    padding: "4px",
    color: "white",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",

      boxShadow: "none",
    },
  },
  ibRefresh: {
    padding: "4px",
    color: "white",
    marginLeft: "8px",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",

      boxShadow: "none",
    },
  },
  btnEdit: {
    backgroundColor: "#111827",
    color: "white",
    width: "30px",
    height: "30px",
    padding: "6px",
    borderRadius: "6px",

    "&:hover": {
      backgroundColor: "#111827",
      color: "white",
    },
  },
}));

function Dsp() {
  const classes = useStyles();
  const history = useHistory();

  const masterMenu = useSelector(state => state.masterMenu);

  const [response, setResponse] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");

  const [afterCheckTopMenu, setAfterCheckTopMenu] = useState(false);
  const { state } = useLocation();
  const [files, setFiles] = useState([]);
  const [modalPayment, setModalPayment] = useState(false);
  const pagesCount = Math.ceil(totalData / rowsPerPage);
  const [loadingTable, setLoadingTable] = useState(false);

  const handleOnSelectedButton = value => {
    history.push(value.data[value.newButtonSelected].link);
  };

  const pageSizePagination = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const handleChangeSearch = event => {
    setSearchKey(event.target.value);
    setPage(1);
    getResponse();
  };

  const handleOnEdit = item => {
    history.push({
      pathname: "/admin/parameter/dsp/detail/" + item.dsp_id,
      state: { row: item },
    });
  };

  const getResponse = () => {
    setLoadingTable(true);
    const url = `${hardBaseUrl}/dsp?page=${page}&size=${rowsPerPage}&search=${searchKey}`;
    let theToken = localStorage.getItem("token");
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        setResponse(res.data.data);
        setTotalData(res.data.meta.total);

        setLoadingTable(false);
      })
      .catch(() => {
        setLoadingTable(false);
      });
  };
  useEffect(() => {
    getResponse();
  }, [rowsPerPage, page, searchKey]);

  useEffect(() => {
    if (masterMenu.masterMenu !== null) {
      setAfterCheckTopMenu(true);
    }
  }, [masterMenu]);

  const handleFiles = e => {
    const data = [];
    Array.from(e.target.files).forEach(file => {
      data.push(file);
    });
    setFiles(data);
  };
  const handleUpload = () => {
    setModalPayment(false);
    const formData = new FormData();
    formData.append("status", "active");
    files.map(val => {
      formData.append("file_name", val);
      return "";
    });

    const url = `${hardBaseUrl}/transaction/proof/${state.customer.publisher_transaction_id}`;
    const theToken = localStorage.getItem("token");
    axios
      .put(url, formData, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(response => {
        Swal.fire({
          icon: response?.data?.message === "success" ? "success" : "error",
          title: response?.data?.message === "success" ? "Success" : "Error",
          text: response?.data?.message,
        }).then(() => {
          history.push("/admin/loan");
        });
      })
      .catch(error => {
        new Error(error);
      });
  };
  return (
    <Page className={classes.root} title="DSP">
      <Container maxWidth={false}>
        <HeaderPage
          title="Parameter"
          breadcrumbs={["Parameter", "Client Name"]}
        />
        {afterCheckTopMenu ? (
          <ButtonGroupTop
            items={topMenuParameter}
            selectedButton={handleOnSelectedButton}
            selectedIndex={0}
          />
        ) : (
          <>{""}</>
        )}

        <Divider className={classes.divider} />
        <Grid container justifyContent="space-between">
          <Grid item>
            <FormControl
              style={{
                marginLeft: "8px",
              }}
            >
              <TextField
                id="fliterText"
                name="filterText"
                className={classes.inputFields}
                type="text"
                variant="outlined"
                placeholder="Search"
                onChange={event => handleChangeSearch(event)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        alt="Logo"
                        src={
                          require("assets/image-public/images/search.svg")
                            .default
                        }
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          className={classes.button}
          style={{
            marginLeft: "8px",
            marginTop: "8px",
          }}
          startIcon={<AddIcon />}
          href="/admin/parameter/client/add"
        >
          <ThemeProvider theme={theme}>
            <Typography
              style={{
                fontSize: "16px",
                textTransform: "none",
              }}
            >
              Add Client Name
            </Typography>
          </ThemeProvider>
        </Button>

        <InnoTable
          columns={[
            {
              name: "name",
              title: "DSP",
              convertFunction: item => {
                return item;
              },
            },
            {
              name: "color",
              title: "Identity Color",

              renderText: function(item) {
                return (
                  <React.Fragment>
                    <div
                      style={{
                        backgroundColor: item,
                      }}
                      className={classes.colorBox}
                    ></div>
                  </React.Fragment>
                );
              },
            },
            {
              name: "person_name",
              title: "Person In Charge",
              convertFunction: item => {
                return item;
              },
            },
            {
              name: "person_email",
              title: "Email",
              convertFunction: item => {
                return item;
              },
            },
            {
              name: "person_phone",
              title: "Phone Number",
              convertFunction: item => {
                return item;
              },
            },
          ]}
          handleChangePage={(e, page) => setPage(page)}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleView={item => {
            history.push({
              pathname: "/admin/parameter/dsp/detail/" + item.dsp_id,
              state: { row: item },
            });
          }}
          handleEdit={item => {
            history.push({
              pathname: `/admin/parameter/client/edit/${item.dsp_id}`,
              state: {
                customer: item,
              },
            });
          }}
          idColumnName={"dsp_id"}
          isLoading={loadingTable}
          isHaveAction={true}
          isUsingCheckbox={false}
          isUsingDeleteConfirmation={true}
          items={response}
          loadingColor={""}
          page={page}
          rowsPerPage={rowsPerPage}
          totalPage={pagesCount}
        />

        <TableContainer className={classes.inner} style={{ display: "none" }}>
          <TableLoading
            open={loadingTable}
            backdropStyle={{ color: "#fff", zIndex: 100, position: "absolute" }}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>DSP</TableCell>
                <TableCell>Identity Color</TableCell>
                <TableCell>Person In Charge </TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {response?.length > 0 ? (
                response.map((row, index) => (
                  <TableRow key={`${row.dsp_id}-${index}`}>
                    <TableCell
                      style={{
                        width: "10%",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <Tooltip title={"Details"} placement="bottom">
                          <IconButton
                            className={classes.btnEdit}
                            style={{
                              backgroundColor: "black",
                            }}
                            onClick={() => {
                              handleOnEdit(row);
                            }}
                          >
                            <VisibilityOutlinedIcon
                              style={{
                                filter: " brightness(0) invert(1)",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Update"} placement="bottom">
                          <IconButton
                            className={classes.btnEdit}
                            style={{
                              backgroundColor: "black",
                              marginLeft: "8px",
                            }}
                          >
                            <RouterLink
                              to={{
                                pathname: `/admin/parameter/client/edit/${row?.dsp_id}`,
                                state: {
                                  customer: row,
                                },
                              }}
                            >
                              <img
                                src={updateIcon}
                                alt="update-icon"
                                style={{
                                  filter: " brightness(0) invert(1)",
                                }}
                              />
                            </RouterLink>
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      <div
                        style={{
                          backgroundColor: row.color,
                        }}
                        className={classes.colorBox}
                      ></div>
                    </TableCell>
                    <TableCell>{row.person_name}</TableCell>
                    <TableCell>{row.person_email}</TableCell>
                    <TableCell>{row.person_phone}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Data not found
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{
            marginTop: "16px",
            display: "none",
          }}
        >
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <div style={{ display: "flex" }}>
                <div style={{ marginTop: "7px" }}>
                  <ThemeProvider theme={theme}>
                    <Typography className={classes.paginationText}>
                      Items Per Page
                    </Typography>
                  </ThemeProvider>
                </div>{" "}
                <div style={{ marginLeft: "24px" }}>{pageSizePagination()}</div>
              </div>
            </Grid>

            <Grid item>
              <Pagination
                count={pagesCount}
                shape="rounded"
                className={classes.pagination}
                onChange={(e, page) => setPage(page)}
                page={page}
              />
            </Grid>
          </Grid>
        </div>
        <React.Fragment>
          <CustomModal
            open={modalPayment}
            handleClose={() => {
              setModalPayment(false);
            }}
            width="460px"
            noPadding
          >
            <Grid
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #E4E7EB",
                padding: "20px",
              }}
            >
              <h1
                style={{
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "30px",
                }}
              >
                Upload Excel
              </h1>
              <IconButton
                style={{ padding: 0 }}
                onClick={() => setModalPayment(false)}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid
              style={{
                padding: "24px",
              }}
            >
              <Grid
                style={{
                  border: "1px dashed #9AA2B1",
                  width: "100%",
                  paddingTop: "16px",
                  paddingBottom: "16px",
                  textAlign: "center",
                  backgroundColor: "#F9FAFB",
                  borderRadius: "8px",
                  position: "relative",
                }}
              >
                <input
                  type="file"
                  multiple
                  onChange={handleFiles}
                  style={{
                    opacity: 0.0,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    width: "100%",
                    height: "100%",
                  }}
                />
                <img
                  alt="Logo"
                  src={
                    require("assets/image-public/images/vector-upload-trans.png")
                      .default
                  }
                />
                <h3
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginTop: "8px",
                  }}
                >
                  Upload Proof Of Payment, <strong>Search File</strong>{" "}
                </h3>
              </Grid>
            </Grid>
            <Grid
              style={{
                padding: "24px",
              }}
            >
              <h3>Document Attached</h3>
              {files.map((res, i) => {
                return (
                  <Grid
                    key={i}
                    style={{
                      marginTop: "12px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="Logo"
                      src={
                        require("assets/image-public/images/vector-doc-icon.png")
                          .default
                      }
                      style={{
                        width: "24px",
                        height: "24px",
                        objectFit: "contain",
                      }}
                    />
                    <Grid
                      style={{
                        fontWeight: 400,
                        fontSize: "14px",
                        width: "100%",
                        paddingLeft: "16px",
                      }}
                    >
                      <p
                        style={{
                          lineHeight: "20px",
                          margin: 0,
                          marginBottom: "4px",
                        }}
                      >
                        {res?.name}
                      </p>
                      <span
                        style={{
                          color: "#687083",
                          lineHeight: "8px",
                        }}
                      >
                        {formatFileSize(res?.size)}
                      </span>
                    </Grid>
                    <Grid
                      container
                      style={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      {}
                      <img alt="Logo" src={PencilVectorGrey} />
                      <IconButton
                        onClick={() =>
                          setFiles([...files].filter(val => val !== res))
                        }
                      >
                        <img alt="LogoTrash" src={TrashVectorGrey} />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid
              style={{
                padding: "24px",
                borderTop: "1px solid #E4E7EB",
              }}
              container
              justifyContent="flex-end"
            >
              <Button
                onClick={() => {
                  setModalPayment(false);
                }}
                className={classes.buttonWhite}
              >
                Cancel
              </Button>
              <Button onClick={handleUpload} className={classes.buttonBlack}>
                Upload
              </Button>
            </Grid>
          </CustomModal>
        </React.Fragment>
      </Container>
    </Page>
  );
}

export default Dsp;
